import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './style.module.scss';

import { downloadFile } from '../../../../services/documents-service';
import { trackEvent } from '../../../../telemetry/TelemetryService';
import DownloadContextMenu from '../../../DownloadContextMenu';

import FolderGrey from '../../../../assets/images/folder-grey.svg';
import FolderBlack from '../../../../assets/images/folder-black.svg';
import ResultIcon from '../../AtriasResultIcon';

const initialState = {
  mouseX: null,
  mouseY: null,
  details: null,
};

function DocumentTable(props) {
  const {
    rows,
    navigateInFolder,
    showAll,
    onSelectedChanged,
    extraColumns,
    t,
  } = props;

  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('asc');
  const [folderIcon, setFolderIcon] = useState(FolderGrey);
  const [contextMenuState, setContextMenuState] = useState(initialState);

  useEffect(() => {
    setSelected([]);
  }, [rows]);

  useEffect(() => {
    onSelectedChanged(selected);
  }, [selected, onSelectedChanged]);

  const isSelected = (row) => selected.map((x) => x.name).indexOf(row.name) !== -1;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.filter((x) => x.type === 1);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const handleCheckboxClick = (event, row) => {
    if (row.type === 1) {
      const selectedIndex = selected.map((x) => x.name).indexOf(row.name);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      setSelected(newSelected);
    } else {
      navigateInFolder(row);
    }
  };

  const handleColumnClick = (row) => {
    if (row.type === 1) {
      trackEvent({ name: 'download', properties: { url: row.url, name: row.name } });
      downloadFile(row.url);
    } else {
      navigateInFolder(row);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    if (property === 'type') {
      setFolderIcon(FolderBlack);
    } else if (folderIcon !== FolderGrey) {
      setFolderIcon(FolderGrey);
    }
  };

  const tableSort = (array, comparator) => array
    .map((el, index) => [el, index])
    .sort((a, b) => {
      const compOrder = comparator(a[0], b[0]);
      if (compOrder !== 0) return compOrder;
      return a[1] - b[1];
    })
    .map((el) => el[0]);

  const descendingComparator = (a, b, compOrderBy) => {
    if (b[compOrderBy] < a[compOrderBy]) {
      return -1;
    }
    if (b[compOrderBy] > a[compOrderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (compOrder, compOrderBy) => (
    compOrder === 'desc'
      ? (a, b) => descendingComparator(a, b, compOrderBy)
      : (a, b) => -descendingComparator(a, b, compOrderBy));

  const headCells = [
    {
      disablePadding: false,
      id: 'name',
      label: t('table.name'),
      align: 'left',
      hideXs: false,
      index: 0,
    },
  ];

  if (extraColumns !== null) {
    extraColumns.map((x) => headCells.push(x));
  }

  const showContextMenu = (event, row) => {
    event.preventDefault();
    if (row.type === 1) {
      setContextMenuState({
        details: { url: row.url, name: row.name },
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
    }
  };

  const closeContextMenu = () => {
    setContextMenuState(initialState);
  };

  const documentTableCellHeaderClass = [styles.table__cell, styles['table__cell--header']];
  const documentTableCellContentClass = [styles.table__cell, styles['table__cell--content']];
  const iconCenterStyle = styles['icons--center'];

  const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell classes={{ root: documentTableCellHeaderClass.join(' ') }} style={{ width: 1 }}>
                <Checkbox
                  disabled={rows.filter((x) => x.type === 1).length === 0}
                  size="small"
                  indeterminate={selected.length > 0 && selected.length < rows.filter((x) => x.type === 1).length}
                  checked={rows.filter((x) => x.type === 1).length > 0 && selected.length === rows.filter((x) => x.type === 1).length}
                  onChange={handleSelectAllClick}
                  classes={{
                    colorSecondary: styles['table__checkbox--color'],
                    checked: styles['table__checkbox--checked'],
                    root: styles.table__checkbox,
                    disabled: styles['table__checkbox--disabled'],
                  }}
                  inputProps={{ 'aria-label': 'select all files' }}
                />
              </TableCell>
              <TableCell classes={{ root: documentTableCellHeaderClass.join(' ') }}>
                <div className={iconCenterStyle}>
                  <img src={folderIcon} onClick={() => handleRequestSort('type')} alt="folder icon" />
                </div>
              </TableCell>
              {headCells
                .sort((a, b) => ((a.index >= b.index) ? 1 : -1))
                .map((headCell) => (
                  <ConditionalWrapper
                    key={headCell.id}
                    condition={headCell.hideXs}
                    wrapper={(children) => <Hidden xsDown>{children}</Hidden>}
                  >
                    <>
                      <TableCell
                        align={headCell.align}
                        classes={{ root: documentTableCellHeaderClass.join(' ') }}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        onClick={() => handleRequestSort(headCell.id)}
                      >
                        <TableSortLabel
                          IconComponent={ExpandMoreRoundedIcon}
                          classes={{
                            root: styles.sort__label,
                            icon: styles['sort__label--icon'],
                            active: styles['sort__label--active'],
                          }}
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    </>
                  </ConditionalWrapper>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableSort(rows, getComparator(order, orderBy))
              .slice(0, showAll && rows.length > 10 ? rows.length : 10)
              .map((row, index) => {
                const isItemSelected = isSelected(row);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    selected={isItemSelected}
                    classes={{
                      selected: styles['row__root--selected'],
                      root: styles.row__root,
                    }}
                  >
                    <TableCell classes={{ root: documentTableCellContentClass.join(' ') }} onClick={(event) => handleCheckboxClick(event, row)}>
                      {row.type === 1
                        ? (
                          <Checkbox
                            size="small"
                            checked={isItemSelected}
                            classes={{
                              colorSecondary: styles['table__checkbox--color'],
                              checked: styles['table__checkbox--checked'],
                              root: styles.table__checkbox,
                            }}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        )
                        : null}
                    </TableCell>
                    <TableCell
                      align="left"
                      onContextMenu={(event) => showContextMenu(event, row)}
                      classes={{ root: documentTableCellContentClass.join(' ') }}
                    >
                      <div
                        className={iconCenterStyle}
                        onClick={() => handleColumnClick(row)}
                        role="presentation"
                      >
                        <ResultIcon isFolder={row.type === 0} fileName={row.name} alt="File icon" />
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      scope="row"
                      id={labelId}
                      onContextMenu={(event) => showContextMenu(event, row)}
                      classes={{ root: documentTableCellContentClass.join(' ') }}
                    >
                      <Grid direction="row" container justify="flex-start" onClick={() => handleColumnClick(row)}>
                        <div>{row.name}</div>
                        {
                          row.type === 1
                            ? (
                              <div className={classNames(iconCenterStyle, styles['icon--hide__sm'])}>
                                <OpenInNewRoundedIcon
                                  className={`m-l-16 ${styles.icons__download}`}
                                />
                              </div>
                            )
                            : null
                        }
                      </Grid>
                    </TableCell>

                    {
                      headCells
                        .sort((a, b) => ((a.index >= b.index) ? 1 : -1))
                        .filter((x) => x.id !== 'name')
                        .map((x) => (
                          <Hidden xsDown key={x.id}>
                            <TableCell
                              align={x.align}
                              onContextMenu={(event) => showContextMenu(event, row)}
                              width="60px"
                              classes={{ root: documentTableCellContentClass.join(' ') }}
                            >
                              <div onClick={() => handleColumnClick(row)} role="presentation">
                                {row[x.id]}
                              </div>
                            </TableCell>
                          </Hidden>
                        ))
                    }
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <DownloadContextMenu context={contextMenuState} close={closeContextMenu} />
    </>
  );
}

DocumentTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  navigateInFolder: PropTypes.func.isRequired,
  showAll: PropTypes.bool.isRequired,
  onSelectedChanged: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  extraColumns: PropTypes.arrayOf(PropTypes.object),
};

DocumentTable.defaultProps = {
  extraColumns: null,
};

export default withTranslation('current-documentation')(DocumentTable);
