import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import SearchInput from '../SearchInput';
import styles from './style.module.scss';
import AtriasError from '../../assets/images/atrias-error.svg';
import { navigateToSearch } from '../../services/search-service';

const Error = ({ t, title, text }) => {
  const history = useHistory();

  const handleSearchClick = (searchObject) => {
    navigateToSearch(searchObject, history);
  };

  return (

    <Grid container direction="column" className={styles['error-container']}>
      <Grid item container direction="row" justify="center">
        <Grid item container className="frame-padded">
          <Grid item className="content-margin">
            <h1 className="jumbotron__title--red">{title}</h1>
          </Grid>
        </Grid>

      </Grid>
      <Grid className={styles['error-image']}>
        <img alt="Error" src={AtriasError} />
      </Grid>

      <Grid item container direction="row" justify="center">
        <Grid item container className="frame-padded">
          <Grid xs={12} item className="content-margin">
            <p className={styles['error-text']}>{text}</p>
            <h1 className={styles['search-title']}>{t('search-title')}</h1>
            <SearchInput searchFunc={handleSearchClick} />
          </Grid>
        </Grid>

      </Grid>
    </Grid>

  );
};

Error.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('error')(Error);
