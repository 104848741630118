/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Grid,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import ProgressiveImage from 'react-progressive-image';

import { getDocs } from '../../services/documents-service';
import { navigateToSearch } from '../../services/search-service';
import DocumentTab from '../../components/DocumentTab';
import SearchJumboTron from '../../components/SearchJumbotron';
import Background from '../../assets/images/backgrounds/new-members_top.jpg';
import Placeholder from '../../assets/images/backgrounds/new-members_top_min.jpg';

import styles from './style.module.scss';

const NewMembers = ({ t }) => {
  const rootFolder = 'New-Members';
  const title = t('title');
  const introText = [t('paragraphs.one'), t('paragraphs.two')];
  const marketModelText = [t('paragraphs.three')];
  const technicalRulesText = [t('paragraphs.four'), t('paragraphs.five')];
  const regulatorsList = [t('regulators.vreg'), t('regulators.cwape'), t('regulators.brugel')];
  const cmsInterfaceText = [t('paragraphs.six')];
  const marketPortalText = [t('paragraphs.seven'), t('paragraphs.eight')];
  const b2bWebServicesText = [t('paragraphs.nine'), t('paragraphs.ten')];
  const qwacText = [t('paragraphs.eleven'), t('paragraphs.twelve')];
  const contractSupplier = [t('contractualObligations.one'), t('contractualObligations.two')];
  const contractBalanceResponsible = [t('contractualObligations.three'), t('contractualObligations.four')];
  const atriasRegistration = [t('paragraphs.fifteen'), t('paragraphs.sixteen'), t('paragraphs.seventeen')];
  const connectionSetup = [t('paragraphs.eighteen'), t('paragraphs.nineteen')];
  const testingAndCertification = [t('paragraphs.twenty'), t('paragraphs.twentyone')];
  const productionConnection = [t('paragraphs.twentytwo')];
  const [files, setFiles] = useState([]);

  useEffect(() => {
    getDocs(rootFolder).then((data) => {
      if (data.length > 0) {
        const marketPaths = data.filter((list) => !String(list.name).startsWith('Archive') && !String(list.name).startsWith('Supporting Documents'));

        setFiles(marketPaths);
      }
    });
  }, []);

  const history = useHistory();

  const handleSearchClick = (searchObject) => {
    if (searchObject.searchType === 2) {
      history.push(`/applications?query=${searchObject.searchTerm}`);
      return;
    }
    navigateToSearch(searchObject, history);
  };
  return (
    <>
      <Helmet>
        <title>{`${t('jumbotron')} | Atrias`}</title>
        <meta name="description" content={t('meta-description')} />
      </Helmet>
      <Grid container direction="column">

        <ProgressiveImage src={Background} placeholder={Placeholder}>
          {(image) => (
            <SearchJumboTron title={t('jumbotron')} background={image} searchFunc={handleSearchClick} />
          )}
        </ProgressiveImage>

        <Grid item container xs={12} justify="center">
          <Grid item container justify="center" className="content-frame-padded">
            <Grid item className={styles.content}>
              <h1>{title}</h1>

              {
                introText.map((item, index) => <p key={index}>{item}</p>)
              }
              <h2 className={styles.h2}>{t('subtitles.one')}</h2>
              <h3>{t('subtitles.two')}</h3>
              {
                marketModelText.map((item, index) => <p dangerouslySetInnerHTML={{ __html: item }} key={index} />)
              }
              <h3>{t('subtitles.three')}</h3>
              {
                technicalRulesText.map((item, index) => <p key={index}>{item}</p>)
              }
              <ul>
                {
                  regulatorsList.map((item, index) => (<li dangerouslySetInnerHTML={{ __html: item }} className={styles.list} key={index} />))
                }
              </ul>
              <h2 className={styles.h2}>{t('subtitles.four')}</h2>
              <h3>{t('subtitles.five')}</h3>
              {
                cmsInterfaceText.map((item, index) => <p key={index}>{item}</p>)
              }
              <h4>{t('subtitles.six')}</h4>
              {
                marketPortalText.map((item, index) => <p key={index}>{item}</p>)
              }
              <h4>{t('subtitles.seven')}</h4>
              {
                b2bWebServicesText.map((item, index) => <p key={index}>{item}</p>)
              }
              <h4>{t('subtitles.eight')}</h4>
              {
                qwacText.map((item, index) => <p key={index}>{item}</p>)
              }
              <h2 className={styles.h2}>{t('subtitles.nine')}</h2>
              <p>{t('paragraphs.thirteen')}</p>
              <ul>
                {
                  contractSupplier.map((item, index) => (<li className={styles.list} key={index}>{item}</li>))
                }
              </ul>
              <p>{t('paragraphs.fourteen')}</p>
              <ul>
                {
                  contractBalanceResponsible
                    .map((item, index) => (<li dangerouslySetInnerHTML={{ __html: item }} className={styles.list} key={index} />))
                }
              </ul>
              <h2 className={styles.h2}>{t('subtitles.ten')}</h2>
              <h3>{t('subtitles.eleven')}</h3>
              {
                atriasRegistration.map((item, index) => <p dangerouslySetInnerHTML={{ __html: item }} key={index} />)
              }
              <h3>{t('subtitles.twelve')}</h3>
              {
                connectionSetup.map((item, index) => <p key={index}>{item}</p>)
              } 
              <h3>{t('subtitles.thirteen')}</h3>
              {
                testingAndCertification.map((item, index) => <p key={index}>{item}</p>)
              }
              <h3>{t('subtitles.fourteen')}</h3>
              {
                productionConnection.map((item, index) => <p key={index}>{item}</p>)
              }
            </Grid>
          </Grid>
        </Grid>

        <Grid item container xs={12} direction="row" justify="center" className="tab-frame push-up-1">
          <Grid item container justify="flex-start" className={styles['documentation-container']}>
            <DocumentTab paths={files} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

NewMembers.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('new-members')(NewMembers);
