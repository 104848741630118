import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

import './style.scss';
import AtriasLink from '../../../Base/AtriasLink';

const QuestionAction = ({ className, t }) => {
  const classes = [
    'side-drawer-action-link',
    className,
  ];

  return (
    <AtriasLink className={classes.join(' ')} to="https://atrias.topdesk.net" target="_blank">{t('menu.actions.question')}</AtriasLink>
  );
};

QuestionAction.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
};

QuestionAction.defaultProps = {
  className: null,
};

export default withTranslation('common')(QuestionAction);
