import React from 'react';
import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { withTranslation } from 'react-i18next';
import Hidden from '@material-ui/core/Hidden';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import AtriasButton from '../Base/AtriasButton';
import './style.scss';
import SearchInput from '../SearchInput';
import { trackEvent } from '../../telemetry/TelemetryService';

const SearchBox = (props) => {
  const {
    t,
    searchFunc,
    query,
    type,
  } = props;
  const quickSearches = [
    { label: 'quick.one.label', search: 'quick.one.search' },
    { label: 'quick.two.label', search: 'quick.two.search' },
    { label: 'quick.three.label', search: 'quick.three.search' }];

  const searchQuickTerm = (term) => {
    trackEvent({ name: 'search', properties: { searchTerm: term, searchType: 0 } });
    searchFunc({ searchType: 0, searchTerm: term });
  };

  return (
    <Card className="search-card">
      <CardContent classes={{ root: 'search-card-content' }}>
        <h2>{t('title')}</h2>
        <SearchInput type={type} query={query} searchFunc={searchFunc} />
        <Hidden smDown>
          <Grid container direction="row" justify="flex-start" alignItems="center" className="p-t-34 m-l-16">
            <SearchRoundedIcon className="icon-font" />
            {quickSearches.map((s, i) => (
              <AtriasButton
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                outlined
                className="quick-search-button"
                handleClick={() => searchQuickTerm(t(s.search))}
                label={t(s.label)}
              />
            ))}
          </Grid>
        </Hidden>
      </CardContent>
    </Card>
  );
};

SearchBox.propTypes = {
  t: PropTypes.func.isRequired,
  searchFunc: PropTypes.func.isRequired,
  query: PropTypes.string,
  type: PropTypes.number,
};

SearchBox.defaultProps = {
  query: '',
  type: 0,
};

export default withTranslation('search-box')(withWidth()(SearchBox));
