import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import styles from './style.module.scss';
import CustomApplicationPopup from '../CustomApplicationPopup';
import DeleteCustomApplicationPopup from '../DeleteCustomApplicationPopup';

const ApplicationMenu = (props) => {
  const { t, application, refreshApps } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialogCustomApp, setOpenDialogCustomApp] = useState(false);

  const [openDialogDeleteCustomApp, setOpenDialogDeleteCustomApp] = useState(false);

  const open = Boolean(anchorEl);

  const openEditMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeEditMenu = () => {
    setAnchorEl(null);
  };

  const editApplication = () => {
    closeEditMenu();
    setOpenDialogCustomApp(!openDialogCustomApp);
  };

  const removeApplication = () => {
    closeEditMenu();
    setOpenDialogDeleteCustomApp(!openDialogDeleteCustomApp);
  };

  const handleEditClose = (forceRefresh) => {
    if (forceRefresh) {
      refreshApps();
    }
    setOpenDialogCustomApp(false);
  };

  const handleDeleteClose = (forceRefresh) => {
    if (forceRefresh) {
      refreshApps();
    }
    setOpenDialogDeleteCustomApp(false);
  };

  return (
    <Grid item>
      <IconButton
        classes={{
          root: styles.button__root,
        }}
        onClick={openEditMenu}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={application.id}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={closeEditMenu}
      >
        <MenuItem
          classes={{ root: styles.item }}
          onClick={editApplication}
        >
          {t('edit')}
        </MenuItem>
        <MenuItem
          classes={{ root: styles.item }}
          onClick={removeApplication}
        >
          {t('remove')}
        </MenuItem>
      </Menu>
      <CustomApplicationPopup
        open={openDialogCustomApp}
        close={handleEditClose}
        isEdit
        application={application}
      />
      <DeleteCustomApplicationPopup
        application={application}
        close={handleDeleteClose}
        open={openDialogDeleteCustomApp}
      />
    </Grid>
  );
};

ApplicationMenu.propTypes = {
  application: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isCustom: PropTypes.bool.isRequired,
    redirectUrl: PropTypes.string.isRequired,
  }).isRequired,
  refreshApps: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('my-atrias')(ApplicationMenu);
