import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import React from 'react';

import FooterContent from './FooterContent';
import CopyrightContent from './CopyrightContent';
import AtriasCorner from '../Base/AtriasCorner';

import './Footer.scss';

const Footer = ({ className }) => {
  const classes = [
    'position-relative',
    className,
  ];

  return (

    <Grid container item xs={12} className={classes.join(' ')} direction="row" justify="center">
      <Hidden mdDown>
        <AtriasCorner color="red" size={150} />
      </Hidden>
      <Hidden lgUp>
        <AtriasCorner color="red" size={75} />
      </Hidden>
      <Grid container item className="p-t-72 frame-padded" direction="column">

        <FooterContent />

        <CopyrightContent />
      </Grid>
    </Grid>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: null,
};

export default Footer;
