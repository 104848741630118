import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import DocumentCard from '../Base/AtriasDocumentCard';
import NoResults from '../NoResults';

function DocumentTab(props) {
  const { paths, t } = props;

  const groups = paths
    .sort((a, b) => {
      const folderA = a.url.toUpperCase();
      const folderB = b.url.toUpperCase();

      let comparison = 0;
      if (folderA > folderB) {
        comparison = 1;
      } else if (folderA < folderB) {
        comparison = -1;
      }
      return comparison;
    })
    .map((folder) => {
      const newFolder = folder;
      while (/^([0-9]{2})/.test(folder.name)) {
        newFolder.name = folder.name.substring(3);
      }
      return newFolder;
    });

  const extraColumns = [
    {
      disablePadding: false,
      id: 'lastModified',
      label: t('table.changed'),
      align: 'right',
      hideXs: true,
      index: 1,
    },
  ];

  if (groups.length === 0) {
    return (
      <NoResults />
    );
  }

  return (
    <Grid item xs={12}>
      {groups.map((card) => (<DocumentCard key={card.name} card={card} extraColumns={extraColumns} />))}
    </Grid>
  );
}

DocumentTab.propTypes = {
  paths: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('current-documentation')(DocumentTab);
