import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { navigateToSearch } from '../../services/search-service';
import { useHistory } from 'react-router-dom';
import FaqCard from './FaqCard';
import styles from './style.module.scss';
import Background from '../../assets/images/backgrounds/jobs_top.jpg';
import Placeholder from '../../assets/images/backgrounds/jobs_top_min.jpg';
import SearchJumboTron from '../../components/SearchJumbotron';

const Faq = () => {
  const { t } = useTranslation('faq');

  const documents = [
    {
      title: 'faq_fr',
      url: 'New-Members/04 UMIG 6.5 Project Documents/FAQ MIG6 Atrias - FR - Points bloqués et facturation tardive.pdf',
    },
    {
      title: 'faq_nl',
      url: 'New-Members/04 UMIG 6.5 Project Documents/FAQ MIG6 Atrias - NL - Geblokkeerde punten en laattijdige facturatie.pdf',
    },
    {
      title: 'faq_en',
      url: 'New-Members/04 UMIG 6.5 Project Documents/FAQ MIG6 Atrias - EN - Blocked points and overdue billing.pdf',
    },
  ];

  const history = useHistory();

  const handleSearchClick = (searchObject) => { 
    navigateToSearch(searchObject, history);
  };

  

  return (
    <Grid container direction="column">
      <Helmet>
        <title>{`${t('jumbotron')} | Atrias`}</title>
        <meta name="description" content={t('meta-description')} />
      </Helmet>
      <SearchJumboTron title={t('jumbotron')} background={Background} searchFunc={handleSearchClick} placeholder={Placeholder} />
      <Grid item container xs={12} justify="center">
        <Grid item container justify="center" className="content-frame-padded">
          <Grid item className="content-margin">
            <h1>{t('title')}</h1>
            <p>{t('paragraphOne')}</p>
            <p>{t('paragraphTwo')}</p>
          </Grid>
          <Grid
            container
            xs={12}
            item
            direction="row"
            className="m-t-56 sector-doc-margin"
          >
            {documents.map((item, index) => (
              // We disable the eslint errors as there is no other unique key we can use
              // eslint-disable-next-line react/no-array-index-key
              <Grid key={index} item xs={12} sm={6} md={5} lg={4} className={styles['document-card']}>
                <FaqCard
                  title={item.title}
                  content={item.content}
                  image={item.image}
                  url={item.url}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

    </Grid>

  );
};

export default Faq;
