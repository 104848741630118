import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import styles from './style.module.scss';
import ApplicationMenu from '../ApplicationMenu';
import ApplicationIcon from '../ApplicationIcon';
import { trackEvent } from '../../../telemetry/TelemetryService';

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    border: 0,
    '&:before': {
      display: 'none',
    },
  },
  content: {
    margin: '24px 0',
    '&$expanded': {
      margin: '24px 0',
    },
  },
  expanded: {},
  expandIcon: {
  },
})(MuiAccordionSummary);

const CategoryItem = (props) => {
  const { t, category, refreshApps } = props;

  const trackClick = (application) => {
    trackEvent({ name: 'my-atrias-application-click', properties: { application: application.name } });
  };

  return (
    <Grid container direction="column">
      <Grid
        item
        container
        xs={12}
        className="documentation-search-container"
        direction="column"
        justify="space-evenly"
      >
        <Accordion
          defaultExpanded
          elevation={0}
          className={styles.accordion__container}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreRoundedIcon />}
            classes={{
              expandIcon: styles.summary__icon,
            }}
          >
            <Grid container direction="row" alignItems="center">
              <Grid item className={styles.title}>
                {t(category.translationKey)}
              </Grid>
              <Grid item className={styles['horizonal-line__container']}>
                <hr className={styles['horizonal-line']} />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: styles.details__root,
            }}
          >
            <Grid container direction="row" justify="flex-start">
              {
                category.applications.map((application) => (
                  <Grid
                    key={application.id}
                    container
                    item
                    alignItems="center"
                    direction="row"
                    justify="flex-start"
                    className={styles.app__container}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                  >
                    <ApplicationIcon name={application.name} icon={application.icon} />

                    <Grid item className={styles.app_title}>
                      {application.name}
                    </Grid>

                    {application.isCustom ? (
                      <ApplicationMenu application={application} refreshApps={refreshApps} />
                    )
                      : null}

                    <a href={application.redirectUrl} onClick={() => trackClick(application)} target="_blank" rel="noopener noreferrer">
                      <span className={
                        classNames(
                          { [styles.app__container__link]: !application.isCustom },
                          { [styles.app__container__link__small]: application.isCustom },
                        )
                      }
                      />
                    </a>
                  </Grid>
                ))
              }
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

CategoryItem.propTypes = {
  category:
    PropTypes.shape({
      translationKey: PropTypes.string.isRequired,
      applications: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          icon: PropTypes.string,
          isCustom: PropTypes.bool.isRequired,
          redirectUrl: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  refreshApps: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('my-atrias')(CategoryItem);
