import axios from 'axios';

const baseUrl = `${window.runConfig.searchUrl}/indexes/documents-index/docs?api-version=2020-06-30`;
const key = `${window.runConfig.apimSubscriptionKey}`;

// eslint-disable-next-line max-len
const filenameQueryParameters = '$select=metadata_storage_name,metadata_storage_path,content&searchFields=custom_field_partial_name&top=1000';
const fileContentQueryParameters = '$select=metadata_storage_name,metadata_storage_path,content&searchFields=content&top=1000&highlight=content';

const mapItems = (items) => items.map((item) => {
  let highlights = null;

  if (item['@search.highlights']) {
    highlights = item['@search.highlights'].content.filter((x) => x.includes('<em>'));
  }
  return {
    metadata_storage_name: item.metadata_storage_name,
    metadata_storage_path: item.metadata_storage_path,
    content: item.content,
    highlights,
  };
});

// eslint-disable-next-line import/prefer-default-export
export const searchFiles = (query) => axios.get(`${baseUrl}&${filenameQueryParameters}&search=${query}`, {
  headers: {
    'subscription-key': key,
  },
}).then((res) => mapItems(res.data.value));

export const searchContent = (query) => axios.get(`${baseUrl}&${fileContentQueryParameters}&search=${query}`, {
  headers: {
    'subscription-key': key,
  },
}).then((res) => {
  const values = res.data.value;

  return mapItems(values);
});

export const navigateToSearch = (searchObject, history) => {
  const link = `/search?query=${searchObject.searchTerm}&type=${searchObject.searchType}`;

  history.push(link);
};

export const truncateHighlight = (highlight) => {
  const maxLength = 100;

  if (highlight.length < maxLength) {
    return highlight;
  }

  const startIndex = highlight.indexOf('<em>');
  const endIndex = highlight.indexOf('</em>');

  let shortPreview = highlight.substring(0, endIndex + maxLength);
  shortPreview = shortPreview.substring(startIndex - maxLength);

  shortPreview = `...${shortPreview}...`;

  return shortPreview;
};
