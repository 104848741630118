import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import styles from './styles.module.scss';
import AtriasLink from '../Base/AtriasLink';

const VersionBanner = (props) => {
  const { environment, version } = props;

  if (environment.toLowerCase() === 'prod') {
    return null;
  }

  return (
    <span className={styles['outer-container']}>
      <Container className={styles['inner-container']}>
        <p className={styles.environment}>
          <span>{environment}</span>
        </p>

        <p className={styles.version}>
          {version}
        </p>
        <span className={styles.spacer} />
        <p>This is a test environment. Looking for the Atrias website?</p>
        <p><AtriasLink className={styles['atrias-link']} to="https://www.atrias.be">www.atrias.be</AtriasLink></p>
      </Container>
    </span>

  );
};

VersionBanner.propTypes = {
  environment: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};

export default VersionBanner;
