import { Tooltip, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const useTooltipStyles = makeStyles((theme) => ({
  arrow: {
    color: 'white',
  },
  tooltip: {
    padding: '32px',
    backgroundColor: 'white',
    color: 'black',
    boxShadow: theme.shadows[10],
    fontSize: '0.9rem',
    marginBottom: '32px',
  },

}));

const AtriasTooltip = (props) => {
  const tooltipClasses = useTooltipStyles();

  const { children } = props;

  return (
    <Tooltip classes={tooltipClasses} {...props}>
      <span>
        {children}
      </span>
    </Tooltip>
  );
};

AtriasTooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AtriasTooltip;
