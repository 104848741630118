import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NavigationItems from '../Desktop/NavigationItems';
import LanguageSelector from '../../LanguageSelector';
import AtriasLogo from '../../Base/AtriasLogo';
import HamburgerButton from '../Mobile/HamburgerButton';
import SideDrawer from '../Mobile/SideDrawer';

import './style.scss';

const Toolbar = (props) => {
  const {
    onSideDrawerOpen,
    onSideDrawerClose,
    onSubMenuHidden,
    width,
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  if (isExpanded === true && isWidthUp('md', width)) {
    setIsExpanded(false);
    onSideDrawerClose();
  }

  const onSideDrawerOpened = (isOpen) => {
    setIsExpanded(isOpen);

    if (isOpen) {
      onSideDrawerOpen();
    } else {
      onSideDrawerClose();
    }
  };

  const onSideDrawerClosed = () => {
    setIsExpanded(false);
    onSideDrawerClose();
  };

  const onToolbarHoverLeave = () => {
    onSubMenuHidden();
  };

  return (
    <Grid container direction="row" onMouseLeave={onToolbarHoverLeave}>
      <Grid className="p-h-16" container item alignItems="stretch">
        <Grid item md={12} container alignItems="stretch">
          <Link className="link" to="/">
            <AtriasLogo />
          </Link>

          <Hidden smDown>
            <Grid item sm container className="m-l-8">
              <Grid container item direction="row" justify="space-between" alignItems="stretch">
                <Grid item xs={12} sm container direction="row">
                  <NavigationItems />
                </Grid>
                <div className="toolbar">
                  <Link to="/search" className="link">
                    <SearchRoundedIcon id="header-search-button" />
                  </Link>
                  <LanguageSelector />
                </div>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item xs container justify="flex-end">
              <SideDrawer
                isExpanded={isExpanded}
                onClose={onSideDrawerClosed}
              />
              <Grid className="p-h-8" item container alignItems="center" justify="flex-end">
                <HamburgerButton handleClick={onSideDrawerOpened} isOpen={isExpanded} />
              </Grid>

            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </Grid>
  );
};

Toolbar.propTypes = {
  onSideDrawerOpen: PropTypes.func.isRequired,
  onSideDrawerClose: PropTypes.func.isRequired,
  onSubMenuHidden: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
};

export default withWidth()(Toolbar);
