/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Hidden from '@material-ui/core/Hidden';
import ProgressiveImage from 'react-progressive-image';
import { useHistory } from 'react-router-dom';

import { useQueryState } from 'react-router-use-location-state';

import NoSearch from './NoSearch';
import ErrorSearch from './ErrorSearch';
import ResultList from './ResultList';
import AtriasSelect from '../../components/Base/AtriasSelect';
import AtriasTabPanel from '../../components/Base/AtriasTabPanel';
import { searchFiles, searchContent } from '../../services/search-service';
import './style.scss';
import Loading from '../../components/Loading';
import NoResult from './NoResult';
import SearchJumboTron from '../../components/SearchJumbotron';
import Background from '../../assets/images/backgrounds/search-top.jpg';
import Placeholder from '../../assets/images/backgrounds/search-top_min.jpg';

const comparator = (a, b) => {
  if (
    /\/Archives?\//.test(b.metadata_storage_path)
    && !/\/Archives?\//.test(a.metadata_storage_path)
  ) {
    return -1;
  }
  if (
    !/\/Archives?\//.test(b.metadata_storage_path)
    && /\/Archives?\//.test(a.metadata_storage_path)
  ) {
    return 1;
  }
  return 0;
};

const Search = ({ t }) => {
  const [searchQuery, setSearchQuery] = useQueryState('query', '');
  const [searchType, setSearchType] = useQueryState('type', 0);
  const [fileResults, setFileResults] = useState([]);
  const [contentResults, setContentResults] = useState([]);
  const [value, setValue] = useState(0);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  useEffect(() => {
    setValue(searchType);
  }, [searchType]);

  useEffect(() => {
    if (searchQuery !== '') {
      setHasErrors(false);
      setLoadingContent(true);
      setLoadingFiles(true);
      searchFiles(searchQuery)
        .then((data) => {
          const filteredData = data.filter(element => !element.metadata_storage_path.includes("/02%20JIT/"));
          setFileResults(filteredData.sort(comparator));
          setLoadingFiles(false);
        })
        .catch(() => setHasErrors(true));
      searchContent(searchQuery)
        .then((data) => {
          const filteredData = data.filter(element => !element.metadata_storage_path.includes("/02%20JIT/"));
          setContentResults(filteredData.sort(comparator));
          setLoadingContent(false);
        })
        .catch(() => setHasErrors(true));;
    }
  }, [searchQuery]);

  const primaryClass = 'tab-primary';
  const tabs = [
    {
      id: 0,
      value: 0,
      label: t('tabs.documents'),
    },
    {
      id: 1,
      value: 1,
      label: t('tabs.in-documentation'),
    },
  ];

  // Higher order components for rendering our results or a message when there are no results
  const FileResult = () => (
    <NoResult
      Component={ResultList}
      resultLength={fileResults.length}
      searchResults={fileResults}
      query={searchQuery}
    />
  );

  const ContentResult = () => (
    <NoResult
      Component={ResultList}
      resultLength={contentResults.length}
      searchResults={contentResults}
      query={searchQuery}
    />
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectChange = (select) => {
    setValue(select.target.value);
  };

  const history = useHistory();
  const handleSearchClick = (searchObject) => {
    if (searchObject.searchType === 2) {
      history.push(`/applications?query=${searchObject.searchTerm}`);
      return;
    }

    setSearchQuery(searchObject.searchTerm);
    setSearchType(searchObject.searchType);
  };

  return (
    <>
      <Helmet>
        <title>{`${t('jumbotron')} | Atrias`}</title>
        <meta name="description" content={t('meta-description')} />
      </Helmet>
      <ProgressiveImage src={Background} placeholder={Placeholder}>
        {(image) => (
          <SearchJumboTron
            title={t('jumbotron')}
            background={image}
            searchFunc={handleSearchClick}
            query={searchQuery}
            type={searchType}
          />
        )}
      </ProgressiveImage>

      <Grid item container xs={12} justify="center">
        <Grid
          item
          container
          justify="flex-start"
          className="content-frame m-t-72"
        >
          <Hidden xsDown>
            <AppBar
              position="static"
              color="default"
              elevation={0}
              classes={{
                root: 'tabs-container',
                colorDefault: 'document-app-bar-color',
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
              >
                {tabs.map((i) => (
                  <Tab
                    key={i.id}
                    label={i.label}
                    classes={{ textColorPrimary: primaryClass }}
                  />
                ))}
              </Tabs>
            </AppBar>
          </Hidden>
          <Hidden smUp>
            <AtriasSelect
              defaultValue={0}
              value={value}
              onChange={handleSelectChange}
              variant="standard"
              disableUnderline
              className="m-l-32 p-v-8 document-tab-selector"
              menuItems={tabs}
            />
          </Hidden>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        direction="row"
        justify="center"
        className="tab-frame push-up-1"
      >
        <Grid item container justify="flex-start" className="content-frame">
          {
            // If there is no searchQuery present, we don't render the tabPanels and display a general NoSearch component
            hasErrors ? (
              <Grid item container justify="center">
                <ErrorSearch />
              </Grid>
            ) : (
              searchQuery ? (
                <>
                  <AtriasTabPanel value={value} index={0}>
                    <Loading
                      className="search-info-text"
                      loading={loadingFiles}
                      label={t('loading-results')}
                    >
                      <FileResult searchResults={fileResults} />
                    </Loading>

                  </AtriasTabPanel>

                  <AtriasTabPanel value={value} index={1}>
                    <Loading
                      className="search-info-text"
                      loading={loadingContent}
                      label={t('loading-results')}
                    >
                      <ContentResult
                        searchResults={contentResults}
                      />
                    </Loading>
                  </AtriasTabPanel>
                </>
              ) : (
                <Grid item container justify="center">
                  <NoSearch />
                </Grid>
              ))
          }
        </Grid>
      </Grid>
    </>
  );
};

Search.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('search')(Search);
