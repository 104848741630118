import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import { getLanguage } from '../i18n';

const reactPlugin = new ReactPlugin();
let appInsights = null;

const createTelemetryService = () => {
  const initialize = (instrumentationKey, browserHistory) => {
    if (!browserHistory) {
      throw new Error('Could not initialize Telemetry Service');
    }
    if (!instrumentationKey) {
      throw new Error('Instrumentation key not provided in ./telemetry/TelemetryProvider.jsx');
    }
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        maxBatchInterval: 0,
        enableCorsCorrelation: true,
        disableFetchTracking: false,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history: browserHistory,
          },
        },
      },
    });

    appInsights.loadAppInsights();
    appInsights.context.application.ver = `${window.runConfig.version} - ${window.runConfig.environment}`;

    appInsights.trackPageView();
    appInsights.addTelemetryInitializer((envelope) => {
      const telemetryItem = envelope.baseData;
      telemetryItem.properties = telemetryItem.properties || {};
      telemetryItem.properties.language = getLanguage();
      telemetryItem.properties.screenWidth = window.innerWidth;
      telemetryItem.properties.screenHeight = window.innerHeight;
    });
  };

  return { reactPlugin, appInsights, initialize };
};

const event = (eventModel) => {
  if (appInsights !== null && appInsights !== undefined) {
    appInsights.trackEvent(eventModel);
  }

  return { event };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;
export const trackEvent = (value) => event(value);
