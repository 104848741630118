import React from 'react';
import PropTypes from 'prop-types';
import AtriasLink from '../../../../../Base/AtriasLink';

const NavigationItemComponent = ({
  className, onClick, link, children,
}) => {
  // Decide if there is a link or if the item is a header for some sub items. Only
  if (!link || typeof (link) !== 'string') {
    return (
      <span tabIndex="0" role="link" className={className} onClick={onClick}>{children}</span>
    );
  }

  return (
    <AtriasLink to={link} className={className}>{children}</AtriasLink>
  );
};

NavigationItemComponent.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  link: PropTypes.string,
  children: PropTypes.node.isRequired,
};

NavigationItemComponent.defaultProps = {
  className: null,
  onClick: null,
  link: null,
};

export default NavigationItemComponent;
