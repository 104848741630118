import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

const NoResults = () => {
  const { t } = useTranslation('common');

  return (
    <Grid item container justify="center" className="content-frame-padded">
      <Grid className="content-margin" item>
        <p className="intro-text">
          {t('no-results')}
        </p>
      </Grid>
    </Grid>
  );
};

export default NoResults;
