import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

import NavigationItem from './NavigationItem';

import { routing, getSubNavigationItems } from '../../../../services/navigation-service';

const NavigationItems = (props) => {
  const {
    onItemClick, onSubCollapse, parentId, t,
  } = props;

  const items = !parentId ? routing : getSubNavigationItems(parentId, t);

  const parent = parentId !== null ? routing.filter((p) => p.id === parentId)[0] : null;

  const parentItem = parent !== null
    ? (
      <NavigationItem
        id={parent.id}
        hasChildren
        isSub
        link={parent.link}
        onClick={onSubCollapse}
      >
        {t(parent.label)}
      </NavigationItem>
    )
    : null;

  return (
    <>
      {parentItem}
      {items.map((i) => (
        <NavigationItem
          hasChildren={(!i.children !== null && i.children !== undefined)}
          id={i.id}
          key={i.id}
          link={i.link}
          onClick={onItemClick}
          isProtected={i.isProtected}
        >
          {t(i.label)}
        </NavigationItem>
      ))}
    </>
  );
};

NavigationItems.propTypes = {
  onItemClick: PropTypes.func,
  onSubCollapse: PropTypes.func,
  parentId: PropTypes.string,
  t: PropTypes.func.isRequired,
};

NavigationItems.defaultProps = {
  onItemClick: null,
  onSubCollapse: null,
  parentId: null,
};

export default withTranslation('common')(NavigationItems);
