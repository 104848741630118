import React from 'react';

import PropTypes from 'prop-types';
import {
  CardContent, Card, Grid,
} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { truncateHighlight } from '../../../services/search-service';
import AtriasFolderPath from '../../../components/Base/AtriasFolderPath';
import ResultIcon from '../../../components/Base/AtriasResultIcon';

import styles from './style.module.scss';

const ResultCard = ({
  title, folders, handleDownloadClick, preview, onContextMenu,
}) => {
  const previewMarkup = () => ({
    __html: truncateHighlight(preview),
  });
  return (
    <Grid item xs={12}>
      <Card key={title}>
        <CardContent className={styles['result-card']}>
          <Grid direction="row" container spacing={4} alignItems="flex-start">
            <Hidden smDown>
              <Grid item>
                <ResultIcon alt="File icon" className={styles['file-icon']} fileName={title} />
              </Grid>
            </Hidden>

            <Grid xs={12} sm={11} item container direction="column" spacing={1}>
              <Grid item>
                <span
                  tabIndex={0}
                  role="button"
                  styling="link"
                  onClick={handleDownloadClick}
                  onContextMenu={onContextMenu}
                  className={styles['result-card__file-name']}
                >
                  {title}
                </span>
              </Grid>
              <Grid container item>
                <AtriasFolderPath folders={folders} />
              </Grid>
              <Grid container item spacing={1} className="m-t-16">
                {/* eslint-disable-next-line react/no-danger */}
                {preview ? (<span className={styles['overlay-container']} dangerouslySetInnerHTML={previewMarkup()} />) : null}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>

  );
};

ResultCard.propTypes = {
  title: PropTypes.string.isRequired,
  folders: PropTypes.arrayOf(PropTypes.string).isRequired,
  preview: PropTypes.string,
  handleDownloadClick: PropTypes.func.isRequired,
  onContextMenu: PropTypes.func.isRequired,
};

ResultCard.defaultProps = {
  preview: null,
};

export default ResultCard;
