import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Motion, spring, presets } from 'react-motion';
import ReactCSSTransitionReplace from 'react-css-transition-replace';

import './style.scss';
import { Grid } from '@material-ui/core';

const HamburgerButton = ({ t, handleClick, isOpen }) => {
  const [toggle, setToggle] = useState(isOpen);

  const onClick = () => {
    setToggle(!toggle);
    handleClick(!toggle);
  };

  useEffect(() => {
    setToggle(isOpen);
  }, [isOpen]);

  const style = {
    cursor: 'pointer',
    // disable touch highlighting on devices
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    color: 'black',
  };

  const Title = () => (toggle ? <div>{t('menu.close')}</div> : <div>{t('menu.menu')}</div>);

  return (
    <Grid onClick={onClick} container item direction="row" justify="flex-end" alignItems="center">
      <ReactCSSTransitionReplace
        className="button-container"
        component="div"
        transitionName="cross-fade"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        <Title key={toggle} />
      </ReactCSSTransitionReplace>

      <svg
        viewBox="0 0 96 96"
        height="20px"
        padding="16px"
        style={style}
      >
        <Motion
          style={{
            x: spring(toggle ? 1 : 0, presets.wobbly),
            y: spring(toggle ? 0 : 1, presets.wobbly),
          }}
        >
          {({ x, y }) => (
            <g
              id="navicon"
              fill="none"
              stroke="currentColor"
              strokeWidth="14"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line
                transform={`translate(${x * 12}, ${x * -7}) rotate(${x * 45}, 7, 26)`}
                x1="7"
                y1="26"
                x2="89"
                y2="26"
              />
              <line
                transform={`translate(${x * 12}, ${x * 7}) rotate(${x * -45}, 7, 70)`}
                x1="7"
                y1="70"
                x2="89"
                y2="70"
              />
              <line
                opacity={y}
                x1="7"
                y1="48"
                x2="89"
                y2="48"
              />
            </g>
          )}
        </Motion>
      </svg>
    </Grid>
  );
};

HamburgerButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default withTranslation('common')(HamburgerButton);
