import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Collapse, ClickAwayListener,
} from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';

import { internalIsActive } from '../../../../../services/navigation-service';

import './style.scss';
import NavigationItemComponent from './NavigationItemComponent';
import SubNavigationItem from '../../SubNavigationItems/SubNavigationItem';

const NavigationItem = (props) => {
  const {
    subItems,
    id,
    link,
    location,
    label,
    onToggle,
  } = props;

  const isActive = internalIsActive(id, link, location.pathname);

  const { t } = useTranslation('common');

  const isExpandable = subItems && subItems.length > 0;

  const [open, setOpen] = useState(false);
  const [hoverOpen, setHoverOpen] = useState(false);
  const [leftSubMargin, setLeftSubMargin] = useState(0);

  const containerRef = useRef();

  const toggleOpen = () => {
    setOpen(!open);
  };

  const onClickAway = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (containerRef.current) {
      const viewportOffset = containerRef.current.getBoundingClientRect();
      const { left } = viewportOffset;
      setLeftSubMargin(left);
    }
  }, [open, hoverOpen]);

  useEffect(() => {
    const shouldShowBorder = open || (hoverOpen && subItems);
    onToggle(shouldShowBorder);
  }, [open, hoverOpen]);

  const onClick = () => {
    setOpen(false);
  };

  const containerClasses = [
    isActive
      ? 'desktop-navigation-item-container-active'
      : 'desktop-navigation-item-container',
  ].join(' ');

  const MenuItemRoot = (
    <NavigationItemComponent className={containerClasses} link={link} onClick={toggleOpen}>
      {label}
    </NavigationItemComponent>
  );

  const MenuItemChildren = isExpandable ? (

    <Collapse
      className="collapse"
      in={open || hoverOpen}
      timeout={0}
    >

      <div style={{ marginLeft: leftSubMargin }} className="inner-collapse">
        {subItems.map((item, index) => (
          <SubNavigationItem
            onClick={onClick}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            link={item.link}
            needsAuthentication={item.needsAuthentication}
          >
            {t(item.label)}
          </SubNavigationItem>
        ))}
      </div>

    </Collapse>

  ) : null;

  return (

    <ClickAwayListener onClickAway={onClickAway}>
      <div onFocus={() => setHoverOpen(true)} onMouseOver={() => setHoverOpen(true)} onMouseLeave={() => setHoverOpen(false)} ref={containerRef}>
        {MenuItemRoot}
        {MenuItemChildren}

      </div>

    </ClickAwayListener>

  );
};

NavigationItem.propTypes = {
  subItems: PropTypes.arrayOf(PropTypes.object),
  onToggle: PropTypes.func.isRequired,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

NavigationItem.defaultProps = {
  link: null,
  id: null,
  subItems: null,
};

export default withWidth()(withRouter(NavigationItem));
