import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { makeStyles } from '@material-ui/core';

import styles from './style.module.scss';
import AtriasButton from '../AtriasButton';
import updateCookieState from './CookieStateChanged';

import { initI18n } from '../../../i18n';

const useStyles = makeStyles((theme) => ({
  decline__button: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: 32,
    },
  },
}));

const AtriasCookieBanner = () => {
  const { t } = useTranslation('banner');
  const classes = useStyles();
  const acceptCookieName = 'accepts-cookies';
  const [cookies, setCookie] = useCookies();
  const [closeBanner, setCloseBanner] = useState(cookies['accepts-cookies'] !== undefined);

  const updateCookie = (value) => {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);
    setCookie(acceptCookieName, value, { path: '/', expires });
    setCloseBanner(true);
  };

  const onDecline = () => {
    updateCookie(false);
    updateCookieState(false);
  };

  const onAccept = () => {
    updateCookie(true);
    updateCookieState(true);
    initI18n(['cookie']);
  };

  return (
    <>
      {closeBanner ? null
        : (
          <Grid container direction="row" justify="center" className={styles.banner}>
            <Grid item container direction="row" alignItems="center" className={styles.banner__container}>
              <Grid item className={styles.banner__text} xs={12} md={8} lg={7}>
                { /* eslint-disable-next-line react/no-danger */}
                <p dangerouslySetInnerHTML={{ __html: t('content') }} />
              </Grid>
              <Grid item container justify="flex-end" xs={12} md={4} lg={5} className={styles.banner__buttons}>
                <AtriasButton label={t('decline')} className={classes.decline__button} handleClick={onDecline} />
                <AtriasButton label={t('accept')} fill handleClick={onAccept} />
              </Grid>
            </Grid>
          </Grid>
        )}
    </>
  );
};

export default AtriasCookieBanner;
