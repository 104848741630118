import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { loginRequest } from '../services/msal-config';

const useIsAuthorized = (groupId) => {
  const [isAuthorized, setIsAuthorized] = useState();

  const isAuthenticated = useIsAuthenticated();

  const { instance, accounts } = useMsal();

  const account = accounts[0];

  useEffect(() => {
    if (!isAuthenticated) {
      setIsAuthorized(false);
    } else {
      instance.acquireTokenSilent(
        {
          account,
          scopes: loginRequest.scopes,
        },
      ).then((response) => {
        if (response.accessToken) {
          const decoded = jwtDecode(response.accessToken);
          const { roles } = decoded;
          if (roles && roles.includes(groupId)) {
            console.log(roles);
            setIsAuthorized(true);
          }
        }
      });
    }
  }, [groupId, isAuthenticated, account, instance]);

  return isAuthorized;
};

export default useIsAuthorized;
