import axios from 'axios';
import { saveAs } from 'file-saver';

const baseUrl = `${window.runConfig.backendUrl}`;
const code = window.runConfig.apimSubscriptionKey;

export const getDocs = (folder) => axios.get(`${baseUrl}/folder/list`, {
  params: { folder },
  headers: { 'subscription-key': code },
})
  .then((response) => response.data.reduce((result, item) => {
    // Moving all files in the root folder to a virtual "Other" folder
    console.log("Response:", response.status);
    console.log("item", item)
    if (item.type === 1) {
      if (!result.some((f) => f.name === '99 Other')) {
        result.push({
          name: '99 Other',
          children: [],
          type: 0,
          lastModified: null,
          url: '99 Other/',
        });
      }

      const index = result.findIndex((x) => x.name === '99 Other');
      result[index].children.push(item);
    } else {
      result.push(item);
    }
    return result;
  }, [])).catch((err) => {
    console.log("error:", err.message)
  });

export const getLatestDocs = (folder) => axios.get(`${baseUrl}/folder/latest`, {
  params: { folder },
  headers: { 'subscription-key': code },
}).then((response) => (response.data.children ? response.data.children : []));

export const getArchiveDocs = (folder) => axios.get(`${baseUrl}/folder/archive`, {
  params: { folder },
  headers: { 'subscription-key': code },
}).then((response) => response.data);

const saveFile = (response) => {
  if (response.status !== 200) {
    throw new Error(response);
  }

  const filename = response.headers['content-disposition']
    .split(';')
    .find((n) => n.includes('filename='))
    .replace('filename=', '')
    .replace(/"/g, '')
    .trim();

  saveAs(response.data, filename);
};

export const downloadZip = (paths) => {
  const offset = new Date().getTimezoneOffset();
  return axios.post(`${baseUrl}/zip`, { paths, offset }, {
    headers: { 'subscription-key': code },
    responseType: 'blob',
  }).then((response) => {
    saveFile(response);
  });
};

export const downloadFile = (path) => window.open(`${baseUrl}/download/${encodeURIComponent(path)}?subscription-key=${code}`);
export const createDownloadLink = (path) => `${baseUrl}/download/${encodeURIComponent(path)}?subscription-key=${code}`;

export const saveAsFile = (path) => axios.get(`${baseUrl}/download/${encodeURIComponent(path)}?subscription-key=${code}`, {
  responseType: 'blob',
})
  .then((response) => {
    saveFile(response);
  });
