import React from 'react';
import PropTypes from 'prop-types';
import './FooterHeader.scss';

const footerHeader = (props) => {
  const { label } = props;
  return (<span className="FooterHeader">{label}</span>);
};

footerHeader.propTypes = {
  label: PropTypes.string.isRequired,
};

export default footerHeader;
