import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import ProgressiveImage from 'react-progressive-image';
import styles from './style.module.scss';

function Jumbotron({ title, background, placeholder }) {
  return (
    <ProgressiveImage src={background} placeholder={placeholder}>
      {(image) => (
        <Grid
          container
          item
          direction="row"
          justify="center"
          xs={12}
          style={{ backgroundImage: `url(${image})` }}
          className={classNames(styles.jumbotron)}
        >
          <h1 className={styles.jumbotron__title}>{title}</h1>
        </Grid>
      )}

    </ProgressiveImage>
  );
}

Jumbotron.propTypes = {
  title: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default Jumbotron;
