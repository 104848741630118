/* eslint-disable */
import './style.scss';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AtriasButton from '../../../components/Base/AtriasButton';
import AtriasTooltip from '../../../components/Base/AtriasTooltip';
import { trackEvent } from '../../../telemetry/TelemetryService';
import AtriasHorizontal from '../../../components/Base/AtriasHorizontal';


const handleContactClick = () => {
    trackEvent({ name: 'topdesk-link-footer' });
    window.open('https://atrias.topdesk.net/', '_blank');
};

const handleEmailClick = () => {
    window.open('mailto:servicedesk@atrias.be');
};
const questionSection = ({ t }) => (
    <section>
        <Grid container item xs={12} direction="row" justify="center">
            <Grid container item className="p-t-56 frame-padded" direction="column">
                <Grid className="m-t-56" container justify="flex-start">
                    <Grid item xs={12} lg={12}>
                        <AtriasHorizontal />
                    </Grid>
                </Grid>
                <Grid container item direction="row" className="p-t-56 content-margin">
                    <Grid container item lg={12} direction="column">
                        <div className="p-r-56">
                            <h1>{t('question.title')}</h1>
                            <p className="contact-text m-t-16">
                                <span>{t('question.content.one')}</span><br />
                                <span>{t('question.content.two')}</span>
                            </p>
                            <p className="contact-text m-t-16">
                                <span>{t('question.content.three')}</span><br />
                                {/* eslint-disable-next-line react/no-danger */}
                                <span dangerouslySetInnerHTML={{ __html: t('question.content.four') }}></span>
                            </p>
                            <p className="contact-text m-t-16">
                                <span>{t('question.content.five')}</span><br />
                                <span>{t('question.content.six')}</span>
                            </p>
                            <p className="contact-text m-t-16">
                                <span>{t('question.content.seven')}</span><br />
                                <span className="bold">{t('question.content.eight')}</span>
                            </p>


                            <Grid container className="m-t-32" spacing={2}>
                                <Grid item>
                                    <AtriasTooltip placement="top" title={t('question.contact-tooltip')} arrow>
                                        <span>
                                            <AtriasButton fill handleClick={handleContactClick} label={t('question.contact')} />
                                        </span>
                                    </AtriasTooltip>
                                </Grid>
                                <Grid item>
                                    <AtriasTooltip placement="top" title={t('question.email-tooltip')} arrow>
                                        <span>
                                            <AtriasButton outlined handleClick={handleEmailClick} label={t('question.email')} />
                                        </span>
                                    </AtriasTooltip>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </section>
);

questionSection.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('home')(questionSection);
