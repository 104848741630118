import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardContent, Grid, Hidden,
} from '@material-ui/core';
import { truncateHighlight } from '../../../services/search-service';
import ResultCard from '../ResultCard';
import MoreResultsCard from '../MoreResultsCard';
import styles from './style.module.scss';

const SearchResult = ({
  handleDownloadClick, title, folders, highlights, onContextMenu,
}) => {
  const [allHighlightsVisible, setAllHighlightsVisible] = useState(false);

  const showAllHighlights = () => {
    setAllHighlightsVisible(true);
  };

  const showMoreResults = !allHighlightsVisible && highlights && highlights.length > 1;

  const createHTML = (preview) => (

    {
      __html: truncateHighlight(preview),
    }
  );

  const AllHighlights = () => {
    const highlightsToShow = highlights.slice(1);

    return highlightsToShow.map((item) => (

      <Card className={styles['highlight-card']}>
        <CardContent className={styles['overlay-container']}>
          <Grid container direction="row">
            <Hidden smDown>
              <Grid className={styles['grid-placeholder']} xs={1} item />
            </Hidden>

            <Grid item xs={12} sm={11} spacing={1}>
              {/* eslint-disable-next-line react/no-danger */}
              <span dangerouslySetInnerHTML={createHTML(item)} />
            </Grid>
          </Grid>

        </CardContent>
      </Card>

    ));
  };

  return (

    <div className={styles['result-container']}>
      <ResultCard
        handleDownloadClick={handleDownloadClick}
        title={title}
        folders={folders}
        preview={highlights ? highlights[0] : null}
        onContextMenu={onContextMenu}
      />

      {(showMoreResults)
        ? (

          <MoreResultsCard handleClick={showAllHighlights} className={styles['more-results-card']} text={`Er zijn nog ${highlights.length - 1} resultaten`} />

        )
        : null}

      {allHighlightsVisible ? <AllHighlights /> : null}
    </div>
  );
};

SearchResult.propTypes = {
  handleDownloadClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  folders: PropTypes.arrayOf(PropTypes.string).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.string),
  onContextMenu: PropTypes.func.isRequired,
};

SearchResult.defaultProps = {
  highlights: null,
};

export default SearchResult;
