import React from 'react';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';
import withWidth from '@material-ui/core/withWidth';
import { withRouter } from 'react-router';

import { AuthenticatedTemplate } from '@azure/msal-react';
import NavigationItemCollapseIcon from './NavigationItemCollapseIcon';
import NavigationItemExpandIcon from './NavigationItemExpandIcon';
import { internalIsActive } from '../../../../../services/navigation-service';

import './style.scss';
import AtriasLink from '../../../../Base/AtriasLink';

const openSubItemsButton = (hasChildren, isSub) => (
  (hasChildren === true && isSub === false)
    ? (
      <Hidden mdUp>
        <NavigationItemExpandIcon />
      </Hidden>
    ) : null);

const closeSubItemsButton = (hasChildren, isSub) => ((hasChildren && isSub)
  ? (
    <Hidden mdUp>
      <NavigationItemCollapseIcon />
    </Hidden>
  ) : null);

const NavigationItem = (props) => {
  const {
    children,
    hasChildren,
    id,
    isSub,
    link,
    location,
    onClick,
    isProtected,
  } = props;

  const isActive = internalIsActive(id, link, location.pathname);

  const classes = [
    isActive ? 'navigation-item-container-active' : 'navigation-item-container',
  ];

  const itemClasses = [
    'navigation-item',
    isActive ? 'navigation-item-active' : null,
  ].join(' ');

  const LinkComponent = () => (link
    ? (
      <AtriasLink onClick={() => onClick(id)} to={link} className={classes.join(' ')}>
        {closeSubItemsButton(hasChildren, isSub)}
        <span className={itemClasses}>{children}</span>
        {openSubItemsButton(hasChildren, isSub)}
      </AtriasLink>
    ) : (
      <div role="button" tabIndex={0} onClick={() => onClick(id)} className={classes.join(' ')}>
        {closeSubItemsButton(hasChildren, isSub)}
        <span className={itemClasses}>{children}</span>
        {openSubItemsButton(hasChildren, isSub)}
      </div>
    )
  );

  return (
    isProtected ? (
      <AuthenticatedTemplate>
        <LinkComponent />
      </AuthenticatedTemplate>
    ) : (<LinkComponent />)
  );
};

NavigationItem.propTypes = {
  children: PropTypes.node.isRequired,
  hasChildren: PropTypes.bool,
  id: PropTypes.string,
  isSub: PropTypes.bool,
  link: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
  isProtected: PropTypes.bool,
};

NavigationItem.defaultProps = {
  link: null,
  hasChildren: false,
  isSub: false,
  id: null,
  onClick: null,
  isProtected: false,
};

export default withWidth()(withRouter(NavigationItem));
