import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import styles from './style.module.scss';

function AtriasTabPanel(props) {
  const {
    children, value, index,
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      style={{ width: '100%' }}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box className={styles['tab-box']} p={3}>{children}</Box>}
    </Typography>
  );
}

AtriasTabPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node]).isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default AtriasTabPanel;
