import React, { useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { saveAsFile } from '../../services/documents-service';

const FileDownload = (props) => {
  const { fileUrl } = props;
  const { t } = useTranslation('file-download');

  useEffect(() => {
    saveAsFile(fileUrl);
  }, []);

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item container direction="row" justify="center">
        <Grid item container className="content-frame-padded">
          <Grid
            item
            container
            direction="column"
            justify="flex-start"
            className="m-b-56 content-
            margin"
          >
            <h1>{t('title')}</h1>
            <p>{t('explanation')}</p>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Button onClick={() => saveAsFile(fileUrl)} variant="contained" color="primary">{t('download')}</Button>
              </Grid>
              <Grid item>
                <Button component={Link} to="/" variant="outlined" color="primary">{t('back-to-site')}</Button>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

FileDownload.propTypes = {
  fileUrl: PropTypes.string.isRequired,
};

export default FileDownload;
