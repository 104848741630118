import React from 'react';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import MenuItem from '@material-ui/core/MenuItem';
import styles from './style.module.scss';

const AtriasSelect = (props) => {
  const {
    id,
    className,
    defaultValue,
    onChange,
    onClose,
    onOpen,
    variant,
    value,
    menuItems,
    disableUnderline,
  } = props;

  return (
    <Select
      id={id}
      {...(value !== undefined && { value })}
      className={className}
      autoWidth={false}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      }}
      defaultValue={defaultValue}
      IconComponent={ExpandMoreRoundedIcon}
      variant={variant}
      classes={{
        root: styles.selector,
        icon: styles.selector__icon,
        iconOpen: styles.selector__icon,
        select: styles.selector__select,
      }}
      {...(variant !== 'outlined' && { disableUnderline })}
      onChange={onChange}
      onClose={onClose}
      onOpen={onOpen}
    >
      {menuItems.map((i) => (
        <MenuItem
          key={i.id}
          value={i.value}
          classes={{
            root: styles.selector,
          }}
          className={i.className !== undefined ? i.className.join(' ') : ''}
        >
          {i.label}
        </MenuItem>
      ))}
    </Select>
  );
};

AtriasSelect.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disableUnderline: PropTypes.bool,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape(
      {
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        className: PropTypes.arrayOf(PropTypes.string),
      },
    ),
  ).isRequired,
};

AtriasSelect.defaultProps = {
  id: '',
  className: '',
  defaultValue: '',
  onChange: () => { },
  onClose: () => { },
  onOpen: () => { },
  variant: 'standard',
  value: undefined,
  disableUnderline: null,
};

export default AtriasSelect;
