import React from 'react';
import { withTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import './style.scss';

const ErrorSearch = ({ t }) => (<p className='error-search-info-text'>
{`${t('error-search')} `}</p>);

ErrorSearch.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('search')(ErrorSearch);
