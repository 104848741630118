import './style.scss';

import React from 'react';
import Grid from '@material-ui/core/Grid';

import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PartnerLogo from '../PartnerLogo';
import Fluvius from '../../../assets/images/logo-fluvius.svg';
import Ores from '../../../assets/images/logo-ores.svg';
import Resa from '../../../assets/images/logo-resa.svg';
import Sibelga from '../../../assets/images/logo-sibelga.svg';
import Arewal from '../../../assets/images/logo-arewal.svg';

const Partners = [
  {
    logo: Fluvius,
    text: 'Logo Fluvius',
    url: 'https://www.fluvius.be',
  },
  {
    logo: Sibelga,
    text: 'Logo Sibelga',
    url: 'https://www.sibelga.be',
  },
  {
    logo: Resa,
    text: 'Logo Resa',
    url: 'http://www.resa.be',
  },
  {
    logo: Ores,
    text: 'Logo Ores',
    url: 'https://www.ores.be',
  },
  {
    logo: Arewal,
    text: 'Logo Arewal',
  },
];

const partnerSection = ({ t }) => (
  <section>
    <Grid item container direction="row" justify="center">
      <Grid item container className="content-frame-padded">
        <Grid item className="content-margin">
          <h1>{t('frames.four.title')}</h1>
          <Grid
            container
            direction="row"
            justify="flex-start"
            className="m-t-56"
            spacing={6}
          >
            {Partners.map((p) => (
              <Grid
                key={p.text}
                container
                justify="flex-start"
                item
                alignItems="center"
                xs={6}
                sm={4}
                md={2}
              >
                <PartnerLogo logo={p.logo} text={p.text} url={p.url} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </section>
);

partnerSection.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('home')(partnerSection);
