import PropTypes from 'prop-types';
import React from 'react';

import './FooterText.scss';

const footerText = (props) => {
  const { label } = props;
  return (<span className="FooterText">{label}</span>);
};

footerText.propTypes = {
  label: PropTypes.string.isRequired,
};

export default footerText;
