import React, { Component } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { history as historyPropTypes } from 'history-prop-types';
import { withCookies } from 'react-cookie';

import { cookieStateSubject } from '../components/Base/AtriasCookieBanner/CookieStateChanged';
import { ai } from './TelemetryService';

class TelemetryProvider extends Component {
  constructor(props) {
    super(props);
    this.state = { initialized: false };
  }

  componentDidMount() {
    const { history, instrumentationKey, cookies } = this.props;
    const { initialized } = this.state;

    const cookie = cookies.cookies['accepts-cookies'];
    if (cookie === 'true') {
      this.startAnalytics(initialized, instrumentationKey, history);
    }

    cookieStateSubject.subscribe((value) => {
      if (value) {
        this.startAnalytics(initialized, instrumentationKey, history);
      }
    });
  }

  startAnalytics(initialized, instrumentationKey, history) {
    if (!initialized && Boolean(instrumentationKey) && Boolean(history)) {
      ai.initialize(instrumentationKey, history);
      this.setState({ initialized: true });
    }
  }

  render() {
    const { children } = this.props;
    return (
      <>{children}</>
    );
  }
}

TelemetryProvider.propTypes = {
  instrumentationKey: PropTypes.string.isRequired,
  history: PropTypes.shape(historyPropTypes).isRequired,
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  cookies: PropTypes.any.isRequired,
};

export default withCookies(withRouter(withAITracking(ai.reactPlugin, TelemetryProvider)));
