import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import RightRoundedRed from '../../../assets/images/right-rounded.svg';
import styles from './style.module.scss';

const AtriasFolderPath = ({ folders, onClick }) => {
  const filePathClassName = [styles['file-path']];
  if (onClick !== null) {
    filePathClassName.push(styles['file-path__pointer']);
  }

  return (
    <Grid item container alignContent="center">
      {folders.map((folder, i) => (
        /* eslint-disable-next-line react/no-array-index-key */
        <React.Fragment key={i}>
          <span
            onClick={() => (onClick !== null ? onClick(i) : null)}
            className={filePathClassName.join(' ')}
            role="button"
            aria-hidden="true"
          >
            {folder}
          </span>
          <img alt="right arrow" src={RightRoundedRed} className={styles['file-path__breadcrumb-arrow']} />
        </React.Fragment>
      ))}
    </Grid>
  );
};

AtriasFolderPath.propTypes = {
  folders: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func,
};

AtriasFolderPath.defaultProps = {
  onClick: null,
};

export default AtriasFolderPath;
