import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Background from '../../assets/images/backgrounds/current-documentation_top.jpg';
import Placeholder from '../../assets/images/backgrounds/current-documentation_top_min.jpg';

import {
  getLatestDocs,
  getArchiveDocs,
} from '../../services/documents-service';
import Documentation from '../../components/Documentation';

const CurrentDocumentation = ({ t }) => {
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState({
    process: [],
    support: [],
  });

  const [archiveFiles, setArchiveFiles] = useState();
  const tabs = [
    {
      id: 0,
      value: 0,
      label: t('tabs.market'),
      paths: files.process,
    },
    {
      id: 1,
      value: 1,
      label: t('tabs.support'),
      paths: files.support,
    },
    {
      id: 2,
      value: 2,
      label: t('tabs.archive'),
      paths: archiveFiles,
    },
  ];

  const title = t('frames.one.title');
  const paragraphs = [
    t('frames.one.content.lineone'),
    t('frames.one.content.linetwo'),
  ];
  const jumbotron = t('jumbotron');

  const rootFolder = 'Actual/UMIG';

  useEffect(() => {
    getLatestDocs(rootFolder).then((data) => {
      if (data.length > 0) {
        const marketPaths = data.filter((list) => String(list.name).startsWith('01 Market Processes'))[0];
        const supportPaths = data.filter((list) => String(list.name).startsWith('02 Supporting Documents'))[0];

        setFiles({
          process: marketPaths.children,
          support: supportPaths.children,
        });
      }

      setLoading(false);
    });
  }, []);

  useEffect(() => {
    getArchiveDocs(rootFolder).then((data) => {
      const virtualRootFolder = [

      ];

      if (data.length > 0) {
        virtualRootFolder.push({
          name: t('tabs.archive'),
          children: data,
          type: 0,
          url: '/',
        });
      }
      setArchiveFiles(virtualRootFolder);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${t('jumbotron')} | Atrias`}</title>
        <meta name="description" content={t('meta-description')} />
      </Helmet>
      <Documentation
        jumbotron={jumbotron}
        title={title}
        tabs={tabs}
        paragraphs={paragraphs}
        background={Background}
        placeholder={Placeholder}
        loading={loading}
      />
    </>
  );
};

CurrentDocumentation.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('current-documentation')(CurrentDocumentation);
