import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import NavigationItem from './NavigationItem';

import { routing as items } from '../../../../services/navigation-service';
import styles from './style.module.scss';

const NavigationItems = (props) => {
  const {
    t,
  } = props;

  const [bottomBorder, setBottomBorder] = useState(items.map((x) => ({ id: x.id, open: false })));
  const updateState = (update) => {
    setBottomBorder((state) => state.map((x) => (x.id === update.id ? { ...x, open: update.open } : x)));
  };

  return (
    <div className={classNames([styles['items-container']], { [styles['items-container__border']]: bottomBorder.some((x) => x.open) })}>
      {items.filter((y) => y.isProtected !== true).map((item) => (
        <NavigationItem
          id={item.id}
          key={item.id}
          link={item.link}
          label={t(item.label)}
          subItems={item.children}
          onToggle={(openState) => updateState({ id: item.id, open: openState })}
        />
      ))}
    </div>
  );
};

NavigationItems.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(NavigationItems);
