import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import MoreResultsCard from '../MoreResultsCard';
import { downloadFile, createDownloadLink } from '../../../services/documents-service';
import SearchResult from '../SearchResult';
import { trackEvent } from '../../../telemetry/TelemetryService';
import styles from './style.module.scss';

import DownloadContextMenu from '../../../components/DownloadContextMenu';

const initialState = {
  mouseX: null,
  mouseY: null,
  details: null,
};

const ResultList = ({ t, searchResults }) => {
  const [showAll, setShowAll] = useState(searchResults.length < 10);
  const [displayedResults, setDisplayedResults] = useState(searchResults.slice(0, 10));
  const [contextMenuState, setContextMenuState] = useState(initialState);

  useEffect(() => {
    if (showAll) {
      setDisplayedResults(searchResults);
    } else {
      setDisplayedResults(searchResults.slice(0, 10));
    }
  }, [showAll, searchResults]);

  const convertURIToArray = (document) => {
    let path = document.metadata_storage_path;
    path = decodeURI(path);

    return path.split('/');
  };

  const getFolders = (document) => {
    const folders = convertURIToArray(document);
    folders.splice(0, 4); // Remove the unneeded parts from the url
    folders.pop(); // Remove the last item, the document name, from the folders.

    return folders;
  };

  const generatePath = (path) => {
    const filePathArray = convertURIToArray(path);
    return filePathArray.splice(4, filePathArray.length).join('/');
  };

  const handleDownloadClick = (document) => {
    const filePath = generatePath(document);
    trackEvent({ name: 'download', properties: { url: createDownloadLink(filePath), name: document.metadata_storage_name } });
    downloadFile(filePath);
  };

  const showContextMenu = (event, item) => {
    event.preventDefault();
    const fileUrl = generatePath(item);
    setContextMenuState({
      details: { url: fileUrl, name: item.metadata_storage_name },
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const closeContextMenu = () => {
    setContextMenuState(initialState);
  };

  return (
    <>
      <Grid item container xs={12} direction="column" justify="flex-start" alignContent="flex-start">

        {displayedResults.map((item, i) => {
          const folders = getFolders(item);
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Grid key={i} item container xs={12}>
              <SearchResult
                onContextMenu={(event) => showContextMenu(event, item)}
                handleDownloadClick={() => handleDownloadClick(item)}
                title={item.metadata_storage_name}
                folders={folders}
                highlights={item.highlights}
              />
            </Grid>
          );
        })}

        {
          showAll
            ? null
            : <MoreResultsCard className={styles['more-results-card']} handleClick={() => setShowAll(true)} text={t('more-results')} />
        }

      </Grid>
      <DownloadContextMenu context={contextMenuState} close={closeContextMenu} />
    </>
  );
};

ResultList.propTypes = {
  searchResults: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('search')(ResultList);
