import React from 'react';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

import './style.scss';

const NavigationButtonExpandIcon = () => (
  <ChevronRightRoundedIcon className="navigation-item-expand-icon" style={{ fontSize: 24 }} />
);

export default NavigationButtonExpandIcon;
