/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import PropTypes from 'prop-types';
import React from 'react';

import Image from '../../../assets/images/atrias-logo.svg';

import styles from './style.module.scss';

const AtriasLogo = ({ className, onClick }) => {
  const classes = [styles['atrias-logo']];

  if (className) {
    classes.push(className);
  }

  if (onClick) {
    classes.push(styles['atrias-logo--clickable']);
  }

  return (
    <img className={classes.join(' ')} onClick={onClick} src={Image} alt="Logo" />
  );
};

AtriasLogo.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

AtriasLogo.defaultProps = {
  className: null,
  onClick: null,
};

export default AtriasLogo;
