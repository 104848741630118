import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import styles from './style.module.scss';

const ApplicationIcon = (props) => {
  const { name, icon } = props;
  return (
    <>
      {icon != null && icon.length > 0 ? (
        <img
          className={styles.app__icon}
          src={icon}
          alt="application icon"
        />
      ) : (
        <Grid container item justify="center" alignItems="center" className={styles.app__icon__letter}>
          <Grid>
            {name[0]}
          </Grid>
        </Grid>
      )}
    </>
  );
};

ApplicationIcon.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

ApplicationIcon.defaultProps = {
  icon: '',
};

export default ApplicationIcon;
