import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Documentation from '../../components/Documentation';
import { getDocs } from '../../services/documents-service';
import Background from '../../assets/images/backgrounds/projects_top.jpg';
import Placeholder from '../../assets/images/backgrounds/projects_top_min.jpg';

const Projects = ({ t }) => {
  const rootFolder = 'Projects';
  const title = t('frames.one.title');
  const paragraphs = [t('frames.one.content.lineone'), t('frames.one.content.linetwo')];
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState({
    projects: [],
    archive: [],
  });

  useEffect(() => {
    getDocs(rootFolder).then((data) => {
      if (data.length > 0) {
        setFiles({
          projects: data[0].children.filter((x) => !String(x.name).includes('Archive')),
          archive: data[0].children.filter((x) => String(x.name).startsWith('Archive')),
        });
      }
      setLoading(false);
    });
  }, []);



  const tabs = [
    {
      id: 0,
      value: 0,
      label: t('tabs.projects'),
      paths: files.projects,
    },
    {
      id: 1,
      value: 1,
      label: t('tabs.archive'),
      paths: files.archive,
    },
  ];
  return (
    <>
      <Helmet>
        <title>{`${t('jumbotron')} | Atrias`}</title>
        <meta name="description" content={t('meta-description')} />
      </Helmet>
      <Documentation
        tabs={tabs}
        title={title}
        jumbotron={t('jumbotron')}
        paragraphs={paragraphs}
        background={Background}
        placeholder={Placeholder}
        loading={loading}
      />
    </>
  );
};

Projects.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('upcoming-model')(Projects);
