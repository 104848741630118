import PropTypes from 'prop-types';
import React from 'react';

import './style.scss';

const PartnerLogo = (props) => {
  const { logo, text, url } = props;
  return (

    <a className="landing-page-partner-link" href={url} target="_blank" rel="noopener noreferrer">
      <img src={logo} alt={text} className="logo-dgo" />
    </a>

  );
};

PartnerLogo.propTypes = {
  logo: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string,
};

PartnerLogo.defaultProps = {
  url: undefined,
};

export default PartnerLogo;
