import React from 'react';
import { withRouter } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ReactRouterPropTypes from 'react-router-prop-types';

import isUrlExternal from 'is-url-external';
import FolderImage from './assets/large-folder.svg';
import FolderProgressImage from './assets/large-file-in-progress.svg';
import CsvImage from './assets/large-csv.svg';

import styles from './style.module.scss';

const AtriasSmallCard = (props) => {
  const {
    subTitle,
    title,
    content,
    icon,
    t,
    link,
    history,
    buttonText
  } = props;

  let iconString = '';
  if (icon === 'folder') {
    iconString = FolderImage;
  } else if (icon === 'file-progress') {
    iconString = FolderProgressImage;
  } else {
    iconString = CsvImage;
  }

  function handleClick() {
    if (isUrlExternal(link)) {
      window.open(link, 'blank');
    } else {
      history.push(link);
    }
  }

  return (
    <Card className={styles.card}>
      <CardContent className="p-0 p-h-16 p-t-16">
        <Grid container direction="column" justify="flex-start" className={styles.card__container}>
          <Grid item>
            <div className={styles['card__sub-title']}>{t(subTitle)}</div>
          </Grid>
          <Grid item>
            <div className={`m-b-8 ${styles.card__title}`}>{t(title)}</div>
          </Grid>
          <Grid item>
            <div className={styles.card__content}>{t(content)}</div>
          </Grid>
          <Grid item container className={styles.card__icon} justify="center">
            <Grid item>
              <img src={iconString} width="88" height="88" alt="folder" />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className="p-0">
        <Button
          className={styles.card__button}
          startIcon={<ArrowForwardRoundedIcon />}
          onClick={handleClick}
        >
          {t(buttonText)}
        </Button>
      </CardActions>
    </Card>
  );
};

AtriasSmallCard.propTypes = {
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(['folder', 'file-progress', 'csv']).isRequired,
  t: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  buttonText: PropTypes.string,
};

AtriasSmallCard.defaultProps = {
  buttonText: 'cards.button',
};

export default withTranslation('home')(withRouter(AtriasSmallCard));
