import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withTranslation } from 'react-i18next';

import styles from './style.module.scss';
import { downloadFile, createDownloadLink, saveAsFile } from '../../services/documents-service';
import { trackEvent } from '../../telemetry/TelemetryService';

function DownloadContextMenu(props) {
  const { context, close, t } = props;
  const { details, mouseX, mouseY } = context;

  const handleClose = (event) => {
    event.preventDefault();
    close();
  };

  const openInNewTab = () => {
    trackEvent({ name: 'download', properties: { url: details.url, name: details.name } });
    downloadFile(details.url);
    close();
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(createDownloadLink(details.url));
    close();
  };

  const saveFile = () => {
    saveAsFile(details.url);
    close();
  };

  return (
    <Menu
      open={details !== null}
      onClose={handleClose}
      onContextMenu={handleClose}
      onScrollCapture={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        mouseY !== null && mouseX !== null
          ? { top: mouseY, left: mouseX }
          : undefined
      }
    >
      <MenuItem
        onClick={copyToClipboard}
        classes={{ root: styles.item }}
      >
        {t('context-menu.copy-to-clipboard')}
      </MenuItem>
      <MenuItem
        onClick={openInNewTab}
        classes={{ root: styles.item }}
      >
        {t('context-menu.open-tab')}
      </MenuItem>
      <MenuItem
        onClick={saveFile}
        classes={{ root: styles.item }}
      >
        {t('context-menu.save-as')}
      </MenuItem>
    </Menu>
  );
}

DownloadContextMenu.propTypes = {
  context: PropTypes.shape({
    details: PropTypes.shape({
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    mouseX: PropTypes.number,
    mouseY: PropTypes.number,
  }).isRequired,
  close: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('current-documentation')(DownloadContextMenu);
