/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
export const routing = [
  {
    id: 'home',
    label: 'menu.home.value',
    link: '/',
  },
  {
    id: 'about',
    label: 'menu.about.value',
    link: null,
    children: [{
      id: 'about/who-what',
      label: 'menu.about.who-what.value',
      link: '/about',
    },
    {
      id: 'about/mission-vision',
      label: 'menu.about.mission-vision.value',
      link: '/about/mission-vision',
    },
    ],
  },
  {
    id: 'jobs',
    label: 'menu.jobs.value',
    link: '/jobs',
  },
  {
    id: 'current-documentation',
    label: 'menu.current-documentation.value',
    link: '/current-documentation',
  },
  {
    id: 'projects',
    label: 'menu.projects.value',
    link: '/projects',
  },
  {
    id: 'new-members',
    label: 'menu.new-members.value',
    link: '/new-members',
  },
  {
    id: 'faq',
    label: 'menu.faq.value',
    link: '/faq',
  },
  {
    id: 'my-atrias',
    label: 'menu.actions.my-atrias',
    link: '/applications',
    isProtected: true,
    onlyMobile: true,
  },
];

export const getSubNavigationItems = (parentId) => {
  if (parentId !== null) {
    const parent = routing.filter((r) => r.id === parentId);
    if (parent.length === 1 && parent[0].children) {
      return parent[0].children;
    }
  }
  return [];
};

const internalGetRoute = (id, routes) => {
  let result = null;
  for (const index in routes) {
    if (routes[index].id === id) {
      return routes[index].link;
    }

    if (routes[index].children) {
      result = internalGetRoute(id, routes[index].children);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

export const internalIsActive = (id, link, location) => {
  if (link === location
    || routing.some((r) => r.id === id && r.children && r.children.some((c) => c.link === location))
  ) {
    return true;
  }
  return false;
};

export const getRoute = (id) => internalGetRoute(id, routing);
