/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Grid,
} from '@material-ui/core';
import { withTranslation} from 'react-i18next';
import { Helmet } from 'react-helmet';
import ProgressiveImage from 'react-progressive-image';

import { getDocs } from '../../services/documents-service';
import { navigateToSearch } from '../../services/search-service';
import DocumentTab from '../../components/DocumentTab';
import SearchJumboTron from '../../components/SearchJumbotron';
import Background from '../../assets/images/backgrounds/sector-data_top.jpg';
import Placeholder from '../../assets/images/backgrounds/sector-data_top_min.jpg';

import styles from './style.module.scss';

const SectorData = ({ t }) => {
  const rootFolder = 'SectorData';
  const title = t('title');
  const introText = [t('paragraphs.one'), t('paragraphs.two')];
  const regulatorsIntro = [t('regulators.arewal'), t('regulators.dnbba'), 
  t('regulators.fluvius'), t('regulators.ores'), t('regulators.resa'), t('regulators.sibelga')];
  const marketConsultation = [t('paragraphs.three'), t('paragraphs.four')];
  const regulatorsElectricity = [t('regulators.monthlyVolume'), t('regulators.minMax')];
  const regulatorsGaz = [t('regulators.monthlyVolumeSecond'), t('regulators.minMaxSecond')];
  const grossCalorificValues =  [t('paragraphs.five'),t('paragraphs.six'),t('paragraphs.seven')];
  const loadProfiles = [t('paragraphs.eight'), t('paragraphs.nine'), t('paragraphs.ten')];
  const [files, setFiles] = useState([]);


  useEffect(() => {
    getDocs(rootFolder).then((data) => {
      if (data.length > 0) {
        const sectorData = data.filter((list) => !String(list.name).startsWith('Archive') && !String(list.name).startsWith('Supporting Documents'));
        setFiles(sectorData);
      }
    });
  }, []);

  const history = useHistory();

  const handleSearchClick = (searchObject) => {
    if (searchObject.searchType === 2) {
      history.push(`/applications?query=${searchObject.searchTerm}`);
      return;
    }
    navigateToSearch(searchObject, history);
  };
  return (
    <>
      <Helmet>
        <title>{`${t('jumbotron')} | Atrias`}</title>
        <meta name="description" content={t('meta-description')} />
      </Helmet>
      <Grid container direction="column">

        <ProgressiveImage src={Background} placeholder={Placeholder}>
          {(image) => (
            <SearchJumboTron title={t('jumbotron')} background={image} searchFunc={handleSearchClick} />
          )}
        </ProgressiveImage>

        <Grid item container xs={12} justify="center">
          <Grid item container justify="center" className="content-frame-padded">
            <Grid item className={styles.content}>
              <h1>{title}</h1>

              {
                introText.map((item, index) => <p key={index}>{item}</p>)  
              }
              <ul>
                {
                  regulatorsIntro.map((item, index) => (<li dangerouslySetInnerHTML={{ __html: item }} className={styles.list} key={index} />))
                }
              </ul>
              <h2 className={styles.h2}>{t('subtitles.one')}</h2>
              {
                marketConsultation.map((item, index) => <p dangerouslySetInnerHTML={{ __html: item }} key={index} />)
              }
              <h3>{t('subtitles.three')}</h3>
              <ul>
                {
                  regulatorsElectricity.map((item, index) => (<li dangerouslySetInnerHTML={{ __html: item }} className={styles.list} key={index} />))
                }
              </ul>
              <h3>{t('subtitles.four')}</h3>
              <ul>
                {
                  regulatorsGaz.map((item, index) => (<li dangerouslySetInnerHTML={{ __html: item }} className={styles.list} key={index} />))
                }
              </ul>
              <h2>{t('subtitles.five')}</h2>
              
              {
                grossCalorificValues.map((item, index) => <p key={index}>{item}</p>)  

              }
              <h2>{t('subtitles.six')}</h2>
              
              {
                loadProfiles.map((item, index) => <p dangerouslySetInnerHTML={{ __html: item }} key={index} />)
              } 
                
            
            </Grid>
          </Grid>
        </Grid>

        <Grid item container xs={12} direction="row" justify="center" className="tab-frame push-up-1">
          <Grid item container justify="flex-start" className={styles['documentation-container']}>
            <DocumentTab paths={files} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

SectorData.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('sector-data')(SectorData);
