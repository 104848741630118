import PropTypes from 'prop-types';
import React from 'react';

import CornerRed from '../../../assets/images/atrias-corner-red.svg';
import CornerWhite from '../../../assets/images/atrias-corner-white.svg';

import styles from './style.module.scss';

const Corner = (props) => {
  const { color, size } = props;

  const src = color === 'red' ? CornerRed : CornerWhite;

  return (
    <img className={styles['corner__float-bottom-right']} width={size} height={size} src={src} alt="Atrias logo" />
  );
};

Corner.propTypes = {
  color: PropTypes.oneOf(['red', 'white']).isRequired,
  size: PropTypes.number.isRequired,
};

export default Corner;
