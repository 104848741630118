import React from 'react';
import { useMsal } from '@azure/msal-react';

const InnerMsalUtilsConfigurator = ({ setMsalRef }) => {
  setMsalRef(useMsal());
  return null;
};

let useMsalRef;

const setUseMsalRef = (useMsalRefProp) => {
  useMsalRef = useMsalRefProp;
};

export const MsalUtilsConfigurator = () => (
  <InnerMsalUtilsConfigurator setMsalRef={setUseMsalRef} />
);

export default {
  getAccessToken(scopes) {
    if (useMsalRef.accounts.length > 0) {
      return useMsalRef.instance.acquireTokenSilent({
        account: useMsalRef.accounts[0],
        scopes,
      }).then((response) => {
        if (response.accessToken) {
          return response.accessToken;
        }
        return null;
      });
    }
    return null;
  },
};
