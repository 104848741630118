import React from 'react';
import { withTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import './style.scss';

const NoSearch = ({ t }) => (<p className="no-search-info-text">{t('no-search')}</p>);

NoSearch.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('search')(NoSearch);
