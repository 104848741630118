import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Grid,
} from '@material-ui/core';
import {
  AuthenticatedTemplate, UnauthenticatedTemplate, useMsal,
} from '@azure/msal-react';
import NavigationItems from '../NavigationItems';

import QuestionAction from '../../Actions/QuestionAction';
import { getSubNavigationItems } from '../../../../services/navigation-service';
import LanguageSelector from '../../../LanguageSelector';
import AtriasButton from '../../../Base/AtriasButton';

import styles from './style.module.scss';

import { loginRequest } from '../../../../services/msal-config';

const SideDrawer = (props) => {
  const {
    isExpanded,
    onClose,
  } = props;

  const { t } = useTranslation('common');
  const { instance } = useMsal();

  const className = [isExpanded ? '' : styles['side-drawer-collapsed'], 'm-t-56', styles['side-drawer']].join(' ');
  const [parentNavId, setParentNavId] = useState(null);

  const onSubCollapse = () => setParentNavId(null);

  const onItemsClick = (id) => {
    const hasChildren = getSubNavigationItems(id).length > 0;
    if (hasChildren === true) {
      setParentNavId(id);
      return;
    }
    setParentNavId(null);
    onClose();
  };

  return (
    <>
      <Grid id="side-drawer" className={className} container item direction="column" wrap="nowrap">
        <NavigationItems
          onItemClick={onItemsClick}
          onSubCollapse={onSubCollapse}
          parentId={parentNavId}
        />
        <Grid container item direction="column" className={styles['side-drawer-action-container']}>
          <Grid container item>

            <AuthenticatedTemplate>
              <AtriasButton
                fill
                label={t('menu.actions.logout')}
                handleClick={() => instance.logout()}
              />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
              <AtriasButton
                fill
                label={t('menu.actions.login')}
                handleClick={() => instance.loginRedirect(loginRequest)}
              />
            </UnauthenticatedTemplate>

          </Grid>
          <Grid container item>
            <QuestionAction className="m-t-24" />
          </Grid>
        </Grid>
        <Grid container item className={styles['side-drawer-language-container']}>
          <LanguageSelector isMobile />
        </Grid>
      </Grid>
    </>
  );
};

SideDrawer.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SideDrawer;
