import React from 'react';
import { Grid } from '@material-ui/core';
import AtriasLogo from '../../assets/images/atrias-logo.svg';
import styles from './style.module.scss';

const PageLoader = () => (
  <Grid className={styles['loading-container']} container justify="center" alignItems="center">
    <img className={styles['logo-loading']} src={AtriasLogo} alt="Logo Atrias" />
  </Grid>
);

export default PageLoader;
