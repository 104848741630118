import axios from 'axios';
import MsalUtils from '../utils/MsalUtilsConfigurator';
import { loginRequest } from './msal-config';

const axiosInstance = axios.create({
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  async (request) => {
    const accessToken = await MsalUtils
      .getAccessToken(loginRequest.scopes)
      .then((at) => at)
      .finally();
    if (accessToken) {
      request.headers = {
        Authorization: `Bearer ${accessToken}`,
        'subscription-key': window.runConfig.apimSubscriptionKey,
      };    } else {
      }

    

    return request;
  },
);

export default axiosInstance;
