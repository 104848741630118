import React from 'react';

import { useHistory } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ProgressiveImage from 'react-progressive-image';
import { navigateToSearch } from '../../../services/search-service';

import SearchJumboTron from '../../../components/SearchJumbotron';
import Background from '../../../assets/images/backgrounds/landing-page_top.jpg';
import Placeholder from '../../../assets/images/backgrounds/landing-page_top_min.jpg';

const SearchSection = ({ t }) => {
  const history = useHistory();

  const handleSearchClick = (searchObject) => {
    if (searchObject.searchType === 2) {
      history.push(`/applications?query=${searchObject.searchTerm}`);
      return;
    }
    navigateToSearch(searchObject, history);
  };

  return (

    <ProgressiveImage src={Background} placeholder={Placeholder}>
      {(image) => (
        <section>
          <SearchJumboTron title={t('jumbotron')} background={image} searchFunc={handleSearchClick} />
        </section>
      )}

    </ProgressiveImage>

  );
};

SearchSection.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('home')(SearchSection);
