import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Hidden from '@material-ui/core/Hidden';
import ProgressiveImage from 'react-progressive-image';
import { useTranslation } from 'react-i18next';

import AtriasTabPanel from '../Base/AtriasTabPanel';
import Loading from '../Loading';
import SearchJumboTron from '../SearchJumbotron';

import './style.scss';
import AtriasSelect from '../Base/AtriasSelect';
import { navigateToSearch } from '../../services/search-service';
import DocumentTab from '../DocumentTab';

const Documentation = ({
  tabs,
  jumbotron,
  title,
  paragraphs,
  background,
  placeholder,
  loading,
}) => {
  const [value, setValue] = useState(0);
  const primaryClass = 'tab-primary';
  const { t } = useTranslation('annual-reports');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectChange = (select) => {
    setValue(select.target.value);
  };

  const history = useHistory();
  const handleSearchClick = (searchObject) => {
    if (searchObject.searchType === 2) {
      history.push(`/applications?query=${searchObject.searchTerm}`);
      return;
    }
    navigateToSearch(searchObject, history);
  };

  return (
    <Grid container direction="column">
      <Grid
        container
        item
        xs={12}
        className="documentation-search-container"
        direction="column"
        justify="space-evenly"
      >
        <ProgressiveImage src={background} placeholder={placeholder}>
          {(image) => (
            <SearchJumboTron
              title={jumbotron}
              background={image}
              searchFunc={handleSearchClick}
            />
          )}
        </ProgressiveImage>
      </Grid>

      <Grid item container xs={12} justify="center">
        <Grid item container justify="center" className="content-frame-padded">
          <Grid item>
            <h1>{title}</h1>
            {
              paragraphs.map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <p key={i}>{item}</p>
              ))
            }
          </Grid>
        </Grid>
      </Grid>

      <Grid item container xs={12} justify="center">
        <Grid item container justify="flex-start" className="content-frame">
          <Hidden xsDown>
            <AppBar
              position="static"
              color="default"
              elevation={0}
              classes={{
                root: 'tabs-container',
                colorDefault: 'document-app-bar-color',
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
              >
                {tabs.map((i) => (
                  <Tab
                    key={i.id}
                    label={i.label}
                    classes={{ textColorPrimary: primaryClass }}
                  />
                ))}
              </Tabs>
            </AppBar>
          </Hidden>
          <Hidden smUp>
            <AtriasSelect
              defaultValue={0}
              value={value}
              onChange={handleSelectChange}
              variant="standard"
              disableUnderline
              className="m-l-32 p-v-8 document-tab-selector"
              menuItems={tabs}
            />
          </Hidden>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        direction="row"
        justify="center"
        className="tab-frame push-up-1"
      >
        <Grid item container justify="flex-start" className="content-frame">
          {tabs.map((tab) => (
            <AtriasTabPanel key={tab.id} value={value} index={tab.id}>
              <Loading
                className="search-info-text"
                loading={loading}
                label={t('loading-files')}
              >
                <DocumentTab paths={tab.paths} />
              </Loading>
            </AtriasTabPanel>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

Documentation.propTypes = {
  jumbotron: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  paragraphs: PropTypes.arrayOf(PropTypes.string).isRequired,
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  background: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Documentation;
