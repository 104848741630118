import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import Chip from '@material-ui/core/Chip';

import styles from './style.module.scss';

import CategoryItem from '../CategoryItem';

const CategoryList = (props) => {
  const {
    t, categories, inError, refreshApps, searchTerm, clearSearch,
  } = props;

  return (
    <Grid container justify="flex-start" direction="column" className="m-b-72">
      {searchTerm.length > 0 ? (
        <Grid
          item
          container
          justify="flex-end"
          direction="row"
          className="m-t-16"
        >
          <Chip
            label={searchTerm}
            variant="outlined"
            classes={{ root: styles.chip, deleteIcon: styles['chip-icon'] }}
            onDelete={() => clearSearch()}
          />
        </Grid>
      ) : null}

      {inError || categories.length === 0 ? (
        <Grid
          container
          direction="column"
          justify="center"
          className="search-info-text"
        >
          <Grid item container direction="row" justify="center">
            <Grid item>
              <ErrorRoundedIcon className={styles.error__icon} />
            </Grid>
            <Grid item>
              <p className={styles.error__text}>
                {inError ? t('error') : t('empty')}
              </p>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="column">
          <Grid item container xs={12} justify="center">
            <Grid item container justify="center">
              {categories.map((category) => (
                <CategoryItem
                  category={category}
                  key={category.translationKey}
                  refreshApps={refreshApps}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

CategoryList.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  inError: PropTypes.bool.isRequired,
  refreshApps: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  clearSearch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('my-atrias')(CategoryList);
