import './style.scss';

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const introSection = ({ t }) => (
  <section>
    <Grid
      item
      container
      xs={12}
      justify="center"
    >
      <Grid item container justify="center" className="content-frame-padded">
        <Grid className="content-margin" item>
          <p className="intro-text">
            {t('frames.one.content')}
          </p>
        </Grid>
        <Grid className="content-margin" item>
          <p className="intro-text-news">
            {t('frames.one.content2')}
          </p>
        </Grid>
      </Grid>
      
    </Grid>
  </section>
);

introSection.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('home')(introSection);
