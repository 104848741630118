import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ProgressiveImage from 'react-progressive-image';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useQueryState } from 'react-router-use-location-state';
import {
  AuthenticatedTemplate, useMsal,
} from '@azure/msal-react';
import Background from '../../assets/images/backgrounds/my-atrias.jpg';
import Placeholder from '../../assets/images/backgrounds/my-atrias_min.jpg';
import Jumbotron from '../../components/Jumbotron';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import { fetchAdApps, searchApps } from '../../services/myatrias-service';
import CategoryList from './CategoryList';
import SearchInput from '../../components/SearchInput';
import { navigateToSearch } from '../../services/search-service';
import styles from './style.module.scss';
import CustomApplicationPopup from './CustomApplicationPopup';
import useIsAuthorized from '../../utils/useIsAuthorized';
import { loginRequest } from '../../services/msal-config';

/**
 * Use a container component so we don't trigger network requests when the auth stage hasn't passed yet.
 */
const MyAtriasContainer = () => {
  const { instance, accounts, inProgress } = useMsal();
  const { t } = useTranslation('my-atrias');

  const isAuthorized = useIsAuthorized(window.runConfig.myAtriasAccessGroup);

  if (!accounts[0] && inProgress === 'none') {
    instance.loginRedirect(loginRequest);
  }

  return (
    <>
      <AuthenticatedTemplate>
        {
          isAuthorized
            ? <MyAtrias />
            : (
              <>
                <Helmet>
                  <title>
                    {t('no-access-meta-title')}
                    {' '}
                    | Atrias
                  </title>
                </Helmet>
                <Error title={t('no-access-title')} text={t('no-access-text')} />
              </>
            )

}
      </AuthenticatedTemplate>
    </>
  );
};

const MyAtrias = () => {
  const { t } = useTranslation('my-atrias');
  const [categoryList, setCategoryList] = useState([]);
  const [loadingApps, setLoadingApps] = useState(true);
  const [inError, setInError] = useState(false);
  const [openDialogNewCustomApp, setOpenDialogNewCustomApp] = useState(false);
  const [searchTerm, setSearchTerm] = useQueryState('query', '');

  const refreshApps = (promise) => {
    setInError(false);
    setLoadingApps(true);
    promise
      .then((data) => {
        console.log(data);
        setCategoryList(data.data);
      })
      .catch((otherError) => {
        // Console.error here because we don't want to swallow our error
        // eslint-disable-next-line no-console
        console.error(otherError.response.data);
        setInError(true);
      })
      .finally(() => {
        setLoadingApps(false);
      });
  };

  const toggleDialogNewCustomApp = () => {
    setOpenDialogNewCustomApp(!openDialogNewCustomApp);
  };

  const handleClose = (needsRefresh) => {
    if (needsRefresh) {
      refreshApps(fetchAdApps());
    }
    setOpenDialogNewCustomApp(false);
  };

  const refreshAppList = () => {
    if (searchTerm.length > 0) {
      refreshApps(searchApps(searchTerm));
    } else {
      refreshApps(fetchAdApps());
    }
  };

  useEffect(() => {
    setLoadingApps(true);
    refreshAppList();
  }, [searchTerm]);

  const history = useHistory();
  const searchFunc = (searchObject) => {
    if (searchObject.searchType === 2) {
      setSearchTerm(searchObject.searchTerm);
    } else {
      navigateToSearch(searchObject, history);
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${t('jumbotron')} | Atrias`}</title>
        <meta name="description" content={t('meta-description')} />
      </Helmet>
      <Grid container direction="column">
        <Grid container item xs={12} direction="column" justify="space-evenly">
          <ProgressiveImage src={Background} placeholder={Placeholder}>
            {(image) => (
              <Jumbotron
                title={t('jumbotron')}
                background={image}
                placeholder={Placeholder}
              />
            )}
          </ProgressiveImage>
        </Grid>
        <Grid container item direction="column" alignItems="center">
          <Grid
            item
            container
            xs={12}
            className={classNames('content-frame', [styles['extra-m-t-20']])}
          >
            <div className={styles['search-input']}>
              <SearchInput
                query={searchTerm}
                searchFunc={searchFunc}
                placeholder="search.app-placeholder"
                type={2}
              />
            </div>

          </Grid>
          <Grid item container xs={12} justify="center">
            <Grid
              item
              container
              direction="column"
              justify="center"
              className="frame-padded"
            >
              <Loading
                loading={loadingApps}
                label={t('loading')}
              >
                <CategoryList
                  categories={categoryList}
                  inError={inError}
                  refreshApps={refreshAppList}
                  clearSearch={() => setSearchTerm('')}
                  searchTerm={searchTerm}
                />
              </Loading>
            </Grid>
          </Grid>
          {!inError && !loadingApps ? (
            <Grid item>
              <Button
                onClick={toggleDialogNewCustomApp}
                className={styles['new-link__button']}
                startIcon={<AddRoundedIcon />}
                disableRipple
              >
                {t('add-custom-app')}
              </Button>
            </Grid>
          ) : null}
          <CustomApplicationPopup
            open={openDialogNewCustomApp}
            close={handleClose}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MyAtriasContainer;
