import React from 'react';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';

import './style.scss';

const NavigationButtonCollapseIcon = () => (
  <ChevronLeftRoundedIcon className="navigation-item-collapse-icon" style={{ fontSize: 24 }} />
);

export default NavigationButtonCollapseIcon;
