import React from 'react';
import PropTypes from 'prop-types';
import { CardContent, Card, Grid } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import styles from './style.module.scss';

const MoreResultsCard = ({ handleClick, text, className }) => (
  <Grid item xs={12} className={className}>
    <Card role="button" styling="link" onClick={handleClick} tabIndex={0}>
      <CardContent className={styles['card-content']}>
        <Grid direction="row" className={styles['more-results-container']} container alignItems="flex-start">
          <Grid item>
            <KeyboardArrowDown color="primary" className={styles['down-arrow']} />
          </Grid>
          <Grid xs={11} item container direction="column" spacing={1}>
            <Grid item>
              <span className={styles['more-results-label']}>{text}</span>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Grid>
);

MoreResultsCard.propTypes = {
  handleClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

MoreResultsCard.defaultProps = {
  className: undefined,
};

export default MoreResultsCard;
