import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

import FooterHeader from '../FooterHeader';
import FooterLink from '../FooterLink';
import FooterText from '../FooterText';

import './style.scss';
import AtriasHorizontal from '../../Base/AtriasHorizontal';


const FooterContent = ({ t }) => (
  <>
    <Grid container item justify="flex-start" direction="column">
      <Grid item xs={12}>
        <AtriasHorizontal />
      </Grid>
    </Grid>

    {/* Grid dividing the question and link block */}
    <Grid container item direction="row" className="p-t-56 content-margin">
      {/* Question block */}
      
      <Grid container item lg={4} direction="column">
              <div className="p-r-56"></div>
      </Grid>

      {/* Link block */}
      <Grid container item lg={8} direction="column">
        {/* Address */}
        <Grid
          direction="row"
          container
          item
          className="footer-address-container"
        >
          <Grid item xs={12} sm={6} md={4} className="footer-address-block">
            <FooterHeader label={t('footer.header.visit-address')} />
            <ul className="footer-block footer-visit-address">
              <li>
                <FooterText label={t('footer.visit-address.line-1')} />
              </li>
              <li>
                <FooterText label={t('footer.visit-address.line-2')} />
              </li>
              <li>
                <FooterText label={t('footer.visit-address.line-3')} />
              </li>
            </ul>
          </Grid>

          <Grid item xs={12} sm={6} md={8} className="footer-address-block">
            <FooterHeader label={t('footer.header.invoice-address')} />
            <ul className="footer-block">
              <li>
                <FooterText label={t('footer.invoice-address.line-1')} />
              </li>
              <li>
                <FooterText label={t('footer.invoice-address.line-2')} />
              </li>
              <li>
                <FooterText label={t('footer.invoice-address.line-3')} />
              </li>
            </ul>
          </Grid>
        </Grid>

        {/* Links */}
        <Grid direction="row" container item>
          <Grid item xs={12} sm={6} md={4} className="footer-link-block">
            <FooterHeader label={t('footer.header.about')} />
            <ul className="footer-block">
              <li>
                <FooterLink label={t('footer.about.who')} path="/about" />
              </li>
              <li>
                <FooterLink
                  label={t('footer.about.mission-and-vision')}
                  path="/about/mission-vision"
                />
              </li>
              <li>
                <FooterLink label={t('footer.about.jobs')} path="/jobs" />
              </li>
              <li>
                <FooterLink
                  label={t('footer.about.company-information')}
                  path="/company-info"
                />
              </li>

              <li>
                <FooterLink
                  label={t('footer.about.annual-reports')}
                  path="/annual-reports"
                />
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className="footer-link-block">
            <FooterHeader label={t('footer.header.documentation')} />
            <ul className="footer-block">
              <li>
                <FooterLink
                  label={t('footer.documentation.current-release')}
                  path="/current-documentation"
                />
              </li>
              <li>
                <FooterLink
                  label={t('footer.documentation.upcoming-model')}
                  path="/projects"
                />
              </li>
              <li>
                <FooterLink
                  label={t('footer.documentation.new-members')}
                  path="/new-members"
                />
              </li>
              <li>
                <FooterLink
                  label={t('footer.documentation.sector-data')}
                  path="/sector-data"
                />
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className="footer-link-block">
            <FooterHeader label={t('footer.header.legal-information')} />
            <ul className="footer-block">
              <li>
                <FooterLink
                  label={t('footer.legal-information.general-conditions')}
                  path="/terms"
                />
              </li>
              <li>
                <FooterLink
                  label={t('footer.legal-information.privacy-policy')}
                  path="/privacy"
                />
              </li>
              <li>
                <FooterLink
                  label={t('footer.legal-information.cookie-policy')}
                  path="/cookie-policy"
                />
              </li>
              <li>
                <FooterLink
                  label={t('footer.legal-information.email-disclaimer')}
                  path="/email-disclaimer"
                />
              </li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid className="m-t-56" container justify="flex-start">
      <Grid item xs={10} lg={11}>
        <AtriasHorizontal />
      </Grid>
    </Grid>
  </>
);

FooterContent.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(FooterContent);
