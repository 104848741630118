import React from 'react';
import {
  Grid, Hidden, Card, CardContent, CardActions,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import ProgressiveImage from 'react-progressive-image';
import AtriasCorner from '../../components/Base/AtriasCorner';
import AtriasButton from '../../components/Base/AtriasButton';
import Jumbotron from '../../components/Jumbotron';

import styles from './style.module.scss';

import Background from '../../assets/images/backgrounds/about_top.jpg';
import Placeholder from '../../assets/images/backgrounds/about_top_min.jpg';
import VisionBackground from '../../assets/images/backgrounds/about_card.jpg';
import VisionPlaceholder from '../../assets/images/backgrounds/about_card_min.jpg';

const About = ({ t }) => {
  const history = useHistory();

  const textBlockClass = 'text-block';

  const handleInfoClick = () => {
    history.push('/about/mission-vision');
  };

  return (
    <Grid container direction="column">
      <Helmet>
        <title>
          {`${t('meta-title')} | Atrias`}
        </title>
        <meta name="description" content={t('meta-description')} />
      </Helmet>
      <Jumbotron title={t('jumbotron')} background={Background} placeholder={Placeholder} />

      <Grid item container className={styles['content-block']} direction="row" justify="center">
        <Grid item container className="frame-padded">
          <Grid item className="content-margin">

            <h1>{t('frames.two.title')}</h1>
            <p className={styles[textBlockClass]}>
              {t('frames.two.content.one')}
            </p>
            <p className={styles[textBlockClass]}>
              {t('frames.two.content.two')}
            </p>
          </Grid>

        </Grid>
      </Grid>

      <ProgressiveImage src={VisionBackground} placeholder={VisionPlaceholder}>
        {(image) => (
          <Grid
            item
            container
            xs={12}
            justify="center"
            style={{ backgroundImage: `url(${image})` }}
            className={styles['vision-card-container']}
          >
            <Grid
              item
              container
              justify="flex-start"
              className="content-frame-padded"
            >
              <Hidden smDown>
                <AtriasCorner color="white" size={150} />
              </Hidden>
              <Grid item xs={12} md={7} lg={6}>
                <Card className={styles['vision-card']}>
                  <CardContent className="card-large-content">
                    <h1>{t('frames.three.title')}</h1>
                    <p>{t('frames.three.content')}</p>
                  </CardContent>
                  <CardActions className="card-large-actions">
                    <AtriasButton
                      fill
                      label={t('frames.three.more-info')}
                      handleClick={handleInfoClick}
                    />
                  </CardActions>
                  <AtriasCorner color="red" size={40} />
                </Card>
              </Grid>
            </Grid>
          </Grid>
        )}
      </ProgressiveImage>
      <Grid item container direction="row" justify="center" className={styles['content-block']}>
        <Grid item container className="frame-padded">
          <Grid item className="content-margin">

            <h1>{t('frames.four.title')}</h1>
            <p className={styles[textBlockClass]}>
              {t('frames.four.content')}
            </p>

          </Grid>

        </Grid>
      </Grid>

      <Grid className={styles['final-content-block']} item container direction="row" justify="center">
        <Grid item container className="frame-padded">
          <Grid item className="content-margin">

            <h1>{t('frames.five.title')}</h1>
            <p className={styles[textBlockClass]}>
              {t('frames.five.content.one')}
            </p>
            <p className={styles[textBlockClass]}>
              {t('frames.five.content.two')}
            </p>

          </Grid>

        </Grid>
      </Grid>
    </Grid>
  );
};

About.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('about')(About);
