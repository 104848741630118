import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';
import ProgressiveImage from 'react-progressive-image';
import { useHistory } from 'react-router';
import AtriasCorner from '../../../components/Base/AtriasCorner';
import AtriasButton from '../../../components/Base/AtriasButton';
import Background from '../../../assets/images/backgrounds/landing-page_card.jpg';
import Placeholder from '../../../assets/images/backgrounds/landing-page_card_min.jpg';

const AtriasInfoSection = ({ t }) => {
  const history = useHistory();
  const handleInfoClick = () => {
    history.push('/about');
  };

  return (
    <ProgressiveImage src={Background} placeholder={Placeholder}>
      {(image) => (
        <section>
          <Grid
            item
            container
            xs={12}
            justify="center"
            style={{ backgroundImage: `url(${image})` }}
            className="background-frame position-relative"
          >
            <Grid
              item
              container
              justify="flex-start"
              className="content-frame-padded"
            >
              <Hidden smDown>
                <AtriasCorner color="white" size={150} />
              </Hidden>
              <Grid item xs={12} md={7} lg={6}>
                <Card className="atrias-info-card card-large position-relative">
                  <CardContent className="card-large-content">
                    <h1>{t('frames.two.title')}</h1>
                    <p>{t('frames.two.content')}</p>
                  </CardContent>
                  <CardActions className="card-large-actions">
                    <AtriasButton
                      fill
                      label={t('frames.two.more-info')}
                      handleClick={handleInfoClick}
                    />
                  </CardActions>
                  <AtriasCorner color="red" size={40} />
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </section>
      )}
    </ProgressiveImage>
  );
};

AtriasInfoSection.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('home')(AtriasInfoSection);
