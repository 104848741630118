import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { withTranslation } from 'react-i18next';

import SearchSection from './SearchSection';
import IntroSection from './IntroSection';
import AtriasInfoSection from './AtriasInfoSection';
import SectorDocumentationSection from './SectorDocumentationSection';
import PartnerSection from './PartnerSection';
import QuestionSection from './QuestionSection';

import './style.scss';

const Home = ({ t }) => (
  <Grid container direction="column">
    <Helmet>
      <title>
        Home | Atrias
      </title>
      <meta name="description" content={t('meta-description')} />
    </Helmet>
    <SearchSection />

    <IntroSection />

    <AtriasInfoSection />

    <SectorDocumentationSection />

    <PartnerSection />

    <QuestionSection />

  </Grid>
);

Home.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('home')(Home);
