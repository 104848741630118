import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import styles from './style.module.scss';

const NoResult = ({
  t, Component, resultLength, query, ...props
}) => {
  if (!resultLength || resultLength === 0) {
    return (
      <p className={styles['no-result-text']}>
        {`${t('no-results')} `/* This is the only way to add spacing before the bolded query without Eslint being mad at me. */}
        <strong>
          &quot;
          {query}
          &quot;
        </strong>
      </p>
    );
  }
  return <Component {...props} />;
};

NoResult.propTypes = {
  Component: PropTypes.func.isRequired,
  resultLength: PropTypes.number,
  query: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

NoResult.defaultProps = {
  resultLength: 0,
};

export default withTranslation('search')(NoResult);
