import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import isUrl from 'is-url';

import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';

import styles from './style.module.scss';
import AtriasButton from '../../../components/Base/AtriasButton';

import { createCustomApp, updateCustomApp } from '../../../services/myatrias-service';

const CustomApplicationPopup = (props) => {
  const {
    t,
    open,
    close,
    isEdit,
    application,
  } = props;
  const [appName, setAppName] = useState(application == null ? '' : application.name);
  const [appLink, setAppLink] = useState(application == null ? '' : application.redirectUrl);
  const [disableForm, setDisableForm] = useState(false);

  const [appNameError, setAppNameError] = useState(false);
  const [appNameErrorText, setAppNameErrorText] = useState('');
  const [appLinkError, setAppLinkError] = useState(false);
  const [appNameLinkText, setAppLinkErrorText] = useState('');

  const [saveFailed, setSaveFailed] = useState(false);
  const [saveFailedErrorText, setSaveFailedErrorText] = useState('');

  const reset = (refresh) => {
    setAppNameError(false);
    setAppLinkError(false);
    setDisableForm(false);
    close(refresh);
  };

  const handleClose = (refresh) => {
    reset(refresh);
    setAppName(!isEdit ? '' : appName);
    setAppLink(!isEdit ? '' : appLink);
  };

  const discardChanges = () => {
    reset(false);
    setAppName(!isEdit ? '' : application.name);
    setAppLink(!isEdit ? '' : application.redirectUrl);
  };

  const saveApplication = () => {
    let valid = true;
    if (appName.length === 0 || appName.length > 31) {
      setAppNameError(true);
      valid = false;
    }

    if (!isUrl(appLink)) {
      setAppLinkError(true);
      valid = false;
    }

    if (valid) {
      setDisableForm(true);

      if (!isEdit) {
        createCustomApp(appName, appLink)
          .then(() => handleClose(true))
          .catch(() => setSaveFailed(true))
          .finally(() => setDisableForm(false));
      } else {
        updateCustomApp(application.id, appName, appLink)
          .then(() => handleClose(true))
          .catch(() => setSaveFailed(true))
          .finally(() => setDisableForm(false));
      }
    }
  };

  useEffect(() => {
    setAppNameErrorText(appNameError ? t('app-name-error') : '');
  }, [appNameError]);

  useEffect(() => {
    setAppLinkErrorText(appLinkError ? t('app-link-error') : '');
  }, [appLinkError]);

  useEffect(() => {
    setSaveFailedErrorText(saveFailed ? t('app-save-error') : '');
  }, [saveFailed]);

  const appNameChanged = (event) => {
    setAppName(event.target.value);
    setAppNameError(false);
  };

  const appLinkChanged = (event) => {
    setAppLink(event.target.value);
    setAppLinkError(false);
  };

  return (
    <Dialog
      disableScrollLock
      fullWidth
      maxWidth="md"
      open={open}
      onClose={discardChanges}
      classes={{
        paper: styles.dialog__paper,
      }}
    >
      <Grid container direction="column">
        <Grid item container direction="row" justify="space-between">
          <Grid item>
            <h1>{!isEdit ? t('add-custom-app') : t('edit-custom-app')}</h1>
          </Grid>
          <Grid item>
            <IconButton
              disabled={disableForm}
              disableRipple
              disableFocusRipple
              focusRipple
              size="small"
              onClick={discardChanges}
              classes={{ root: styles.dialog__close }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          <p>{!isEdit ? t('add-custom-app-description') : t('edit-custom-app-description')}</p>
        </Grid>
        <Grid item container className={styles['text-field__container']}>
          <TextField
            disabled={disableForm}
            error={appNameError}
            helperText={appNameErrorText}
            variant="outlined"
            value={appName}
            label={t('app-name')}
            classes={{
              root: styles['max-width'],
            }}
            onChange={appNameChanged}
            inputProps={{ maxLength: 250 }}
          />
        </Grid>
        <Grid item container className="m-t-32">
          <TextField
            disabled={disableForm}
            error={appLinkError}
            helperText={appNameLinkText}
            variant="outlined"
            placeholder="https://"
            value={appLink}
            label={t('app-link')}
            classes={{
              root: styles['max-width'],
            }}
            onChange={appLinkChanged}
            inputProps={{ maxLength: 250 }}
          />
        </Grid>
        <Grid item className={styles.action__container}>
          <FormHelperText error={saveFailed}>{saveFailedErrorText}</FormHelperText>
          <AtriasButton
            disabled={disableForm}
            label={t('app-save')}
            handleClick={saveApplication}
            fill
            className={styles['max-width']}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

CustomApplicationPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  application: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    redirectUrl: PropTypes.string.isRequired,
  }),
  t: PropTypes.func.isRequired,
};

CustomApplicationPopup.defaultProps = {
  isEdit: false,
  application: null,
};

export default withTranslation('my-atrias')(CustomApplicationPopup);
