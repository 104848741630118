export const msalConfig = {
  auth: {
    clientId: window.runConfig.azureAdAppId,
    authority: window.runConfig.azureAdAuthority,
    redirectUri: window.runConfig.redirectUri,
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: window.location.origin,
  },
};

export const loginRequest = {
  scopes: window.runConfig.userScopes.split(','),
};
