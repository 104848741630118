import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import './style.scss';

const CopyrightContent = ({ t }) => (
  <Grid container direction="row" justify="flex-start" alignItems="center" className="copyright-container">
    <Grid item className="p-v-24" xs={12}>
      <span className="copyright-text">{t('footer.copyright')}</span>
    </Grid>
  </Grid>
);

CopyrightContent.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(CopyrightContent);
