import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

import styles from './style.module.scss';
import SearchBox from '../SearchBox';

const useStyles = makeStyles({
  container: (props) => ({
    '&::before': {
      backgroundImage: `url(${props.background})`,
    },
  }),
});

const SearchJumboTron = ({
  title, background, searchFunc, query, type,
}) => {
  const classes = useStyles({ background });

  return (
    <div className={styles['shell-container']}>
      <div className={classNames(classes.container, styles.shell)}>
        <header className={styles['shell-header']}>
          <h1 className={styles['shell-header__title']}>
            {title}
          </h1>
        </header>
        <section className={styles['shell-body']}>
          <div className={styles['shell-body__content']}>
            <SearchBox searchFunc={searchFunc} query={query} type={type} />
          </div>
        </section>
      </div>
    </div>
  );
};

SearchJumboTron.propTypes = {
  title: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  searchFunc: PropTypes.func.isRequired,
  query: PropTypes.string,
  type: PropTypes.number,
};

SearchJumboTron.defaultProps = {
  query: '',
  type: 0,
};

export default SearchJumboTron;
