import React from 'react';
import PropTypes from 'prop-types';
import isExternal from 'is-url-external';
import { NavLink } from 'react-router-dom';

/**
 * Component that wraps the <NavLink> component from react-router to add support for external links.
 */
const AtriasLink = (props) => {
  const { to, className, children } = props;

  // Anchor content is provided by the component that uses this component.
  // If the link is external, we open it in a new tab by default.
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return (isExternal(to)
    ? <a href={to} className={className} rel="noreferrer noopener" target="_blank" {...props}>{children}</a>
    : <NavLink exact to={to} {...props} />);
};

AtriasLink.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

AtriasLink.defaultProps = {
  className: '',
};

export default AtriasLink;
