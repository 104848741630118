import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, OutlinedInput, FormHelperText, InputAdornment, withWidth, isWidthUp,
} from '@material-ui/core';

import Error from '@material-ui/icons/ErrorRounded';
import { useTranslation } from 'react-i18next';
import AtriasSelect from '../Base/AtriasSelect';
import AtriasButton from '../Base/AtriasButton';
import { trackEvent } from '../../telemetry/TelemetryService';
import useIsAuthorized from '../../utils/useIsAuthorized';

import styles from './style.module.scss';

const SearchInput = ({
  type, query, searchFunc, width, placeholder,
}) => {
  const isAuthorized = useIsAuthorized(window.runConfig.myAtriasAccessGroup);
  const { t } = useTranslation('search-box');

  const [searchType, setSearchType] = useState(type);
  const [searchTypes, setSearchTypes] = useState([
    { id: 0, value: 0, label: 'type.documents' },
    { id: 1, value: 1, label: 'type.content' },
  ]);
  const [searchTerm, setSearchTerm] = useState(query);
  const [validation, setValidation] = useState(null);
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    if (searchTerm.length >= 250) {
      setValidation(
        {
          message: t('search.too-long'),
          className: 'search-card__validation-text',
        },
      );
    } else {
      setValidation(null);
    }
  }, [searchTerm]);

  useEffect(() => {
    setSearchTerm(query);
    setSearchType(type);
  }, [query, type]);

  useEffect(() => {
    if (isAuthorized) {
      setSearchTypes([...searchTypes, { id: 2, value: 2, label: 'type.application' }]);
    }
  }, [isAuthorized]);

  const searchTermAction = () => {
    if (searchTerm.length < 2) {
      setInvalid(true);
      setValidation(
        {
          message: t('search.not-enough-chars'),
          className: 'search-card__validation-text__error',
        },
      );
    } else {
      trackEvent({ name: 'search', properties: { searchTerm, searchType } });
      setInvalid(false);
      searchFunc({ searchType, searchTerm });
    }
  };

  const enterPressed = (key) => {
    if (key.key === 'Enter') {
      searchTermAction();
    }
  };

  const ConditionalValidationMessage = () => {
    if (validation) {
      return (
        <FormHelperText
          className={styles[validation.className]}
        >
          {validation.message}
        </FormHelperText>
      );
    }
    return null;
  };

  const conditionalAdornment = () => {
    if (invalid) {
      return (<InputAdornment className={styles.adornment} position="end"><Error /></InputAdornment>);
    }
    return null;
  };

  let direction = 'column';
  if (isWidthUp('sm', width)) {
    direction = 'row';
  }

  return (
    <Grid container direction={direction} justify="flex-end" className="content-container">
      <Grid item className="stretch position-relative">
        <OutlinedInput
          variant="outlined"
          value={searchTerm}
          id="search-term"
          placeholder={t(placeholder)}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="left-side"
          onKeyUp={(e) => enterPressed(e)}
          classes={{ input: 'search-input-field' }}
          endAdornment={conditionalAdornment()}
          inputProps={{ maxLength: 250 }}
        />
        <ConditionalValidationMessage />
      </Grid>
      <Grid item>
        <AtriasSelect
          value={searchType}
          className={styles['right-side']}
          defaultValue={0}
          onChange={(e) => setSearchType(e.target.value)}
          variant="outlined"
          menuItems={searchTypes.map((x) => ({ ...x, label: t(x.label) }))}
        />
      </Grid>
      <Grid item>
        <AtriasButton fill label={t('search.action')} className={styles['search-button']} handleClick={searchTermAction} />
      </Grid>
    </Grid>
  );
};

SearchInput.propTypes = {
  type: PropTypes.number,
  query: PropTypes.string,
  searchFunc: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

SearchInput.defaultProps = {
  query: '',
  type: 0,
  placeholder: 'search.placeholder',
};

export default withWidth()(SearchInput);
