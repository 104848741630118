import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Auxiliary from '../../hoc/Auxiliary';
import Footer from '../Footer';
import Header from '../Header';

import './Layout.scss';
import VersionBanner from '../VersionBanner/VersionBanner';

const Layout = ({ children }) => {
  const [isSideDrawerVisible, setIsSideDrawerVisible] = useState(false);
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);

  const onSideDrawerOpened = () => {
    if (isSideDrawerVisible === false) {
      setIsSideDrawerVisible(true);
    }
  };

  const onSideDrawerClosed = () => {
    if (isSideDrawerVisible === true) {
      setIsSideDrawerVisible(false);
    }
  };

  const onSubMenuVisible = () => {
    if (isSubMenuVisible === false) {
      setIsSubMenuVisible(true);
    }
  };

  const onSubMenuHidden = () => {
    if (isSubMenuVisible === true) {
      setIsSubMenuVisible(false);
    }
  };

  const contentClasses = [
    'content',
    isSideDrawerVisible ? 'content-hidden' : null,
    isSubMenuVisible ? 'content-sub-expanded' : null,
  ];

  const footerClasses = [
    isSideDrawerVisible ? 'footer-hidden' : null,
  ];

  return (
    <Auxiliary>
      { isSideDrawerVisible ? null : <VersionBanner environment={window.runConfig.environment} version={window.runConfig.version} />}
      <Header
        onSideDrawerOpen={onSideDrawerOpened}
        onSideDrawerClose={onSideDrawerClosed}
        onSubMenuVisible={onSubMenuVisible}
        onSubMenuHidden={onSubMenuHidden}
      />
      <main className={contentClasses.join(' ')}>
        {children}
      </main>
      <Footer className={footerClasses.join(' ')} />
    </Auxiliary>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
