import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import { initI18n } from './i18n';

import PageLoader from './components/PageLoader';

import * as serviceWorker from './serviceWorker';

import App from './App';
import './index.scss';

function getCookieValue(a) {
  const b = document.cookie.match(`(^|;)\\s*${a}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : '';
}

if (getCookieValue('accepts-cookies') === 'true') {
  initI18n(['cookie']);
} else {
  initI18n([]);
}

const rootEle = document.getElementById('root');

createRoot(rootEle).render(
  <Suspense fallback={<PageLoader />}>
    <App />
  </Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();