import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';

import styles from '../CustomApplicationPopup/style.module.scss';
import AtriasButton from '../../../components/Base/AtriasButton';

import { deleteCustomApp } from '../../../services/myatrias-service';

const DeleteCustomApplicationPopup = (props) => {
  const {
    t,
    open,
    close,
    application,
  } = props;
  const [disableForm, setDisableForm] = useState(false);

  const [deleteFailed, setDeleteFailed] = useState(false);
  const [deleteFailedErrorText, setDeleteFailedErrorText] = useState('');

  useEffect(() => {
    setDeleteFailedErrorText(deleteFailed ? t('app-delete-error') : '');
  }, [deleteFailed]);

  const deleteApplication = () => {
    setDisableForm(true);

    deleteCustomApp(application.id)
      .then(() => close(true))
      .catch(() => setDeleteFailed(true))
      .finally(() => setDisableForm(false));
  };

  const handleClose = () => {
    setDeleteFailed(false);
    setDeleteFailedErrorText('');
    close(false);
  };

  return (
    <Dialog
      disableScrollLock
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      classes={{
        paper: styles.dialog__paper,
      }}
    >
      <Grid container direction="column">
        <Grid item container direction="row" justify="space-between">
          <Grid item>
            <h1>{t('delete-custom-app')}</h1>
          </Grid>
          <Grid item>
            <IconButton
              disabled={disableForm}
              disableRipple
              disableFocusRipple
              focusRipple
              size="small"
              onClick={() => close()}
              classes={{ root: styles.dialog__close }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          <p>{t('delete-custom-app-description', { name: application.name })}</p>
        </Grid>
        <Grid item className={styles.action__container}>
          <FormHelperText error={deleteFailed}>{deleteFailedErrorText}</FormHelperText>
          <Grid item container justify="flex-end" spacing={2}>
            <Grid item xs={12} md={3}>
              <AtriasButton
                disabled={disableForm}
                outlined
                label={t('app-cancel')}
                className={styles['max-width']}
                handleClick={handleClose}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <AtriasButton
                disabled={disableForm}
                label={t('app-delete')}
                handleClick={deleteApplication}
                className={styles['max-width']}
                fill
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

DeleteCustomApplicationPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  application: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('my-atrias')(DeleteCustomApplicationPopup);
