import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const getVariant = (fill, outlined) => {
  if (fill && outlined) {
    throw Error('fill & outlined cannot both be true');
  }

  if (fill) return 'contained';

  if (outlined) return 'outlined';

  return null;
};

const AtriasButton = (props) => {
  const {
    fill,
    label,
    icon,
    handleClick,
    id,
    outlined,
    className,
    disabled,
    children,
    href,
  } = props;

  const classNameArray = className !== null ? className.split(' ') : [];
  const variant = getVariant(fill, outlined);
  const fillClassName = fill ? styles['button--contained'] : styles['button--outlined'];

  const buttonLabel = label !== null ? label : children;

  if (buttonLabel === null && icon === null) {
    throw new Error("AtriasButton doesn't have label or icon.");
  }

  if (icon !== null) {
    if (buttonLabel === null) {
      classNameArray.push(styles['icon-button']);
      return (
        <IconButton
          id={id}
          className={classNameArray.join(' ')}
          onClick={handleClick}
          disabled={disabled}
          href={href}
          target="_blank"
        >
          {icon}
        </IconButton>
      );
    }

    classNameArray.push(styles.button);
    classNameArray.push(fillClassName);
    return (
      <Button
        id={id}
        className={classNameArray.join(' ')}
        disableRipple={fill}
        onClick={handleClick}
        startIcon={icon}
        variant={variant}
        disabled={disabled}
        href={href}
        target="_blank"
      >
        {buttonLabel}
      </Button>
    );
  }

  classNameArray.push(styles.button);
  classNameArray.push(fillClassName);
  return (
    <Button
      id={id}
      className={classNameArray.join(' ')}
      disableRipple={fill}
      onClick={handleClick}
      variant={variant}
      disabled={disabled}
      href={href}
      target="_blank"
    >
      {buttonLabel}
    </Button>
  );
};

AtriasButton.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.string,
  fill: PropTypes.bool,
  outlined: PropTypes.bool,
  handleClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  href: PropTypes.string,
};

AtriasButton.defaultProps = {
  id: null,
  icon: null,
  label: null,
  fill: false,
  outlined: false,
  className: null,
  disabled: false,
  children: null,
  handleClick: null,
  href: null,
};

export default AtriasButton;
