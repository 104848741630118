import React from 'react';
import PropTypes from 'prop-types';
import File from '../../../assets/images/file.svg';
import Csv from '../../../assets/images/csv.svg';
import OtherFile from '../../../assets/images/other-file.svg';
import Presentation from '../../../assets/images/presentation.svg';
import Folder from '../../../assets/images/folder.svg';

const ResultIcon = ({
  fileName, isFolder, alt, ...props
}) => {
  const getIcon = (file) => {
    if (isFolder) {
      return Folder;
    }

    if (file.endsWith('.pdf')) {
      return File;
    }
    if (file.endsWith('.xlsx') || file.endsWith('.xls') || file.endsWith('.csv')) {
      return Csv;
    }
    if (file.endsWith('.pptx') || file.endsWith('.ppt')) {
      return Presentation;
    }
    return OtherFile;
  };

  const icon = getIcon(fileName);

  return <img {...props} alt={alt} src={icon} />;
};

ResultIcon.propTypes = {
  fileName: PropTypes.string.isRequired,
  isFolder: PropTypes.bool,
  alt: PropTypes.string.isRequired,
};

ResultIcon.defaultProps = {
  isFolder: false,
};

export default ResultIcon;
