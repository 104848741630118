import './style.scss';

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AtriasSmallCard from '../../../components/Base/AtriasSmallCard';

const sectorDocumentation = [
  {
    subTitle: 'cards.faq.subtitle',
    title: 'cards.faq.title',
    content: 'cards.faq.content',
    icon: 'folder',
    link: '/faq',
    buttonText: 'cards.faq.buttonText',
  },
  {
    subTitle: 'cards.one.subtitle',
    title: 'cards.one.title',
    content: 'cards.one.content',
    icon: 'folder',
    link: '/current-documentation',
  },
  {
    subTitle: 'cards.sectordata.subtitle',
    title: 'cards.sectordata.title',
    content: 'cards.sectordata.content',
    icon: 'folder',
    link: '/sector-data',
  },
];

const sectorDocumentationSection = ({ t }) => (
  <section>
    <Grid item container direction="row" justify="center">
      <Grid item className="content-frame-padded">
        <Grid container justify="flex-start" direction="column">
          <Grid item container className="content-margin">
            <h1>{t('frames.three.title')}</h1>
            <p>{t('frames.three.content')}</p>
          </Grid>
          <Grid
            container
            xs={12}
            item
            direction="row"
            className="m-t-56 sector-doc-margin"
          >
            {sectorDocumentation.map((item, index) => (
              // We disable the eslint errors as there is no other unique key we can use
              // eslint-disable-next-line react/no-array-index-key
              <Grid key={index} item xs={12} sm={6} md={6} lg={3} className="document-card">
                <AtriasSmallCard
                  subTitle={item.subTitle}
                  title={item.title}
                  content={item.content}
                  icon={item.icon}
                  link={item.link}
                  buttonText={item.buttonText}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </section>
);

sectorDocumentationSection.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('home')(sectorDocumentationSection);
