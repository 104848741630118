import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import LanguageRoundedIcon from '@material-ui/icons/LanguageRounded';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';

import i18n, { getLanguage } from '../../i18n';

import './style.scss';

const LanguageSelector = (props) => {
  const { t, isMobile } = props;
  const [selectedLanguage, setSelectedLanguage] = useState(getLanguage());

  const handleChange = (event) => {
    setSelectedLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  let languages;
  let languageIcon;

  const devLanguage = { label: 'BASE', value: 'base' };

  if (isMobile) {
    languages = [
      { label: t('languages.nl.long'), value: 'nl' },
      { label: t('languages.fr.long'), value: 'fr' },
      { label: t('languages.en.long'), value: 'en' },
    ];

    languageIcon = (
      <div id="language-selector-icon-container">
        <LanguageRoundedIcon />
      </div>
    );
  } else {
    languages = [
      { label: t('languages.nl.short'), value: 'nl' },
      { label: t('languages.fr.short'), value: 'fr' },
      { label: t('languages.en.short'), value: 'en' },
    ];
  }

  if (window.runConfig.environment === 'DEV' || window.runConfig.environment === 'local') {
    languages.push(devLanguage);
  }

  return (
    <Grid item container xs alignItems="center">
      {languageIcon}
      <Select
        id="language-selector"
        classes={{
          icon: 'language-selector-icon',
          select: 'language-selector-select',
        }}
        defaultValue={selectedLanguage}
        disableUnderline
        IconComponent={ExpandMoreRoundedIcon}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
        onChange={handleChange}
      >
        {languages.map((l) => (
          <MenuItem
            classes={{
              root: 'language-selector-item-root',
            }}
            key={l.value}
            value={l.value}
          >
            {l.label}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};

LanguageSelector.propTypes = {
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

LanguageSelector.defaultProps = {
  isMobile: false,
};

export default withTranslation('common')(LanguageSelector);
