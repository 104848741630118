import PropTypes from 'prop-types';
import React from 'react';
import './style.scss';
import LockRounded from '@material-ui/icons/LockRounded';
import AtriasLink from '../../../../Base/AtriasLink';

const SubNavigationItem = (props) => {
  const {
    children,
    link,
    onClick,
    needsAuthentication,
  } = props;

  const containerClass = [
    'sub-navigation-item-container',
  ].join(' ');

  return (
    <div className={containerClass}>
      <AtriasLink
        to={link}
        onClick={onClick}
        className="sub-navigation-item"
        activeClassName="sub-navigation-item-active"
      >
        <div className="text-container">
          {children}
          {needsAuthentication ? <LockRounded className="lock-icon" /> : null}
        </div>
      </AtriasLink>
    </div>
  );
};

SubNavigationItem.propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  needsAuthentication: PropTypes.bool,
};

SubNavigationItem.defaultProps = {
  onClick: null,
  needsAuthentication: false,
};

export default SubNavigationItem;
