import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';

import './style.scss';

const Loading = ({
  loading, label, children,
}) => {
  if (loading) {
    return (
      <>
        <p className="search-info-text">{label}</p>
        <LinearProgress className="loader" />
      </>

    );
  }
  return children;
};

Loading.propTypes = {
  loading: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default Loading;
