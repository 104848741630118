import React, { useRef, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import withWidth from '@material-ui/core/withWidth';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import classNames from 'classnames';

import AtriasButton from '../AtriasButton';
import { downloadZip } from '../../../services/documents-service';
import DocumentTable from './DocumentTable';

import styles from './style.module.scss';
import AtriasFolderPath from '../AtriasFolderPath';
import { trackEvent } from '../../../telemetry/TelemetryService';

function DocumentCard(props) {
  const {
    t,
    card,
    simpleHeader,
    extraColumns,
  } = props;

  const [breadCrumbs, setBreadCrumbs] = useState([card.name]);
  const [displayedRows, setDisplayedRows] = useState(card.children);
  const [showAll, setShowAll] = useState(card.children.length < 10);
  const [selectedRows, setSelectedRows] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [downloadFailed, setDownloadFailed] = useState(false);

  const ref = useRef();

  const navigateInFolder = (row) => {
    setBreadCrumbs([...breadCrumbs, row.name]);
    setDisplayedRows(row.children);
    setShowAll(row.children.length < 10);

    const { top } = ref.current.getBoundingClientRect();
    if (top < 0) {
      window.scrollTo({ top: ref.current.offsetTop - 50, left: 0 });
    }
  };

  const navigateToCrumb = (crumbIndex) => {
    setDownloadFailed(false);

    let folder = card;
    const localBreadCrumbs = breadCrumbs;
    const newBreadCrumbs = [breadCrumbs[0]];

    // removing the top folder because folder == card at start
    localBreadCrumbs.shift();

    for (let i = 0; i < crumbIndex; i += 1) {
      const index = folder.children.findIndex((x) => x.name === localBreadCrumbs[i]);
      folder = folder.children[index];
      newBreadCrumbs.push(localBreadCrumbs[i]);
    }

    setDisplayedRows(folder.children);
    setBreadCrumbs(newBreadCrumbs);
    setShowAll(folder.children.length < 10);
  };

  const showAllRows = () => {
    setShowAll(true);
  };

  const handleDownloadClick = (rows) => {
    setDownloading(true);
    setDownloadFailed(false);
    rows.forEach((x) => {
      trackEvent({ name: 'download', properties: { url: x.url, name: x.name } });
    });

    downloadZip(rows.map((x) => x.url))
      .then(() => {
        setDownloading(false);
      })
      .catch(() => {
        setDownloadFailed(true);
        setDownloading(false);
      });
  };

  const changeSelectedRows = (rows) => {
    setSelectedRows(rows);
  };

  const downloadButton = (
    <AtriasButton
      outlined
      disabled={selectedRows.length === 0 || downloading}
      className={styles['download-files__button']}
      handleClick={() => handleDownloadClick(selectedRows)}
    >
      {downloading ? <CircularProgress color="inherit" size={16} className={styles['download-files__animation']} /> : t('download.label')}
    </AtriasButton>
  );

  const downloadError = (
    <>
      <ErrorRoundedIcon className={styles.error__icon} />
      <div className={styles.error__text}>{t('common:download-failed')}</div>
    </>
  );

  return (
    <Card key={card.name} className={styles.card} ref={ref}>
      <CardContent className="p-0">
        <Grid container direction="column" className={styles.grey__border}>
          {!simpleHeader ? (
            <>
              <Grid
                item
                container
                justify="space-between"
                className={classNames(
                  'p-l-32',
                  'm-t-32',
                  'm-b-24',
                )}
              >
                <h2>{card.name}</h2>
                <Hidden smDown>
                  {downloadButton}
                </Hidden>
              </Grid>
              <Grid item container direction="row" justify="space-between" alignContent="center" className="p-l-32 m-b-16">
                <Grid item className={styles.breadCrumbs}><AtriasFolderPath folders={breadCrumbs} onClick={navigateToCrumb} /></Grid>
                <Hidden smDown>
                  {downloadFailed
                    ? (
                      <Grid item container className={styles.error} justify="flex-end">
                        {downloadError}
                      </Grid>
                    ) : null}
                </Hidden>
              </Grid>
            </>
          )
            : (
              <>
                <Hidden smDown>
                  <Grid
                    item
                    container
                    justify="flex-end"
                    className={classNames(
                      'p-l-32',
                      'm-t-32',
                      { [styles['download-files__button--margin']]: simpleHeader && !downloadFailed },
                      { [styles['download-failed--margin']]: simpleHeader && downloadFailed },
                    )}
                  >

                    {downloadButton}
                  </Grid>
                  {downloadFailed
                    ? (
                      <Grid item container direction="row" justify="flex-end" alignContent="center" className="p-l-32 m-b-16">
                        <Grid item container className={styles.error} justify="flex-end">
                          {downloadError}
                        </Grid>
                      </Grid>
                    ) : <div className="m-t-16" />}
                </Hidden>
              </>
            )}
          <Hidden mdUp>
            <Grid
              item
              className={classNames(
                'p-l-32',
                { 'm-t-32': simpleHeader },
                { [styles['download-files__button--margin']]: !downloadFailed },
                { [styles['download-failed--margin']]: downloadFailed },
              )}
            >
              {downloadButton}
            </Grid>
            {downloadFailed
              ? (
                <Grid
                  item
                  container
                  className={classNames('p-l-32', 'm-b-16', styles.error)}
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  {downloadError}
                </Grid>
              ) : <div className="m-t-16" />}
          </Hidden>
        </Grid>

        <div>
          <div className="m-h-32">
            <DocumentTable
              rows={displayedRows}
              showAll={showAll}
              navigateInFolder={navigateInFolder}
              onSelectedChanged={changeSelectedRows}
              extraColumns={extraColumns}
            />
            {
              !showAll
                ? (
                  <Grid container direction="row" justify="flex-start" className={styles['extra-rows']} onClick={() => showAllRows()}>
                    <KeyboardArrowDownRoundedIcon className={styles['extra-rows__icon']} />
                    <span className={styles['extra-rows__button']}>
                      {t('table.extra-rows', { count: card.children.length })}
                    </span>
                  </Grid>
                )
                : null
            }
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

DocumentCard.propTypes = {
  // Using objects because can't really create a shape for it because type references itself in the children prop.
  /* eslint-disable-next-line react/forbid-prop-types */
  card: PropTypes.object.isRequired,
  simpleHeader: PropTypes.bool,
  t: PropTypes.func.isRequired,
  extraColumns: PropTypes.arrayOf(PropTypes.shape({
    disablePadding: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    align: PropTypes.string.isRequired,
    hideXs: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
  })),
};

DocumentCard.defaultProps = {
  simpleHeader: false,
  extraColumns: null,
};

export default withTranslation(['current-documentation', 'common'])(withWidth()(DocumentCard));
