import axiosInstance from './axiosInstance';

const baseUrl = window.runConfig.adMappingUrl;

export const fetchAdApps = () => axiosInstance.get(`${baseUrl}/Category/list`);

export const searchApps = (searchTerm) => axiosInstance.get(`${baseUrl}/Category/list?filter=${encodeURI(searchTerm)}`);

export const createCustomApp = (name, link) => axiosInstance.post(`${baseUrl}/CustomApplication`, {
  name,
  redirectUrl: link,
});

export const updateCustomApp = (id, name, link) => axiosInstance.put(`${baseUrl}/CustomApplication`, {
  id,
  name,
  redirectUrl: link,
});

export const deleteCustomApp = (id) => axiosInstance.delete(`${baseUrl}/CustomApplication/${id}`);
