import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';

import styles from './style.module.scss';
import { saveAsFile } from '../../../services/documents-service';

const FaqCard = (props) => {
  const { t } = useTranslation('faq');
  const {
    title,
    content,
    url,
  } = props;

  const handleClick = () => {
    saveAsFile(url);
  };

  return (
    <Card className={styles.card}>
      <CardContent className="p-0">
        <Grid container direction="column" justify="flex-start" className={styles.card__container}>
          <Grid item>
            <div className={`m-b-8 ${styles.card__title}`}>{t(title)}</div>
          </Grid>
          <Grid item>
            <div className={styles.card__content}>{t(content)}</div>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className="p-0">
        <Button
          className={styles.card__button}
          startIcon={<ArrowForwardRoundedIcon />}
          onClick={handleClick}
        >
          {t('download-button')}
        </Button>
      </CardActions>
    </Card>
  );
};

FaqCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default FaqCard;
