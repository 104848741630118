import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import './style.scss';

const footerLink = (props) => {
  const { path, label } = props;
  return (<Link className="footer-link" to={path}>{label}</Link>);
};

footerLink.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default footerLink;
