import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import {
  AuthenticatedTemplate, UnauthenticatedTemplate, useMsal,
} from '@azure/msal-react';
import { loginRequest } from '../../../services/msal-config';

import AtriasTooltip from '../../Base/AtriasTooltip';

import styles from './style.module.scss';

import { trackEvent } from '../../../telemetry/TelemetryService';

const headerMiniLinkClassName = 'header-mini-link';

const HeaderMini = () => {
  const { t } = useTranslation('common');

  const LoginNavItem = () => {
    const { instance } = useMsal();

    return (
      <>
        <AuthenticatedTemplate>
          <span
            role="button"
            tabIndex={0}
            onClick={() => instance.logout()}
            className={styles[headerMiniLinkClassName]}
          >
            {t('menu.actions.logout')}
          </span>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <span
            role="button"
            tabIndex={0}
            onClick={() => instance.loginRedirect(loginRequest)}
            className={styles[headerMiniLinkClassName]}
          >
            {t('menu.actions.login')}
          </span>
        </UnauthenticatedTemplate>
      </>
    );
  };

  return (
    <>
      <div id={styles['header-mini']}>
        <div id={styles['header-mini-content']}>

          <div id={styles['desktop-header-mini-link-container-question']}>
            <AtriasTooltip placement="top" title={t('footer.question.contact-tooltip')} arrow>
              <a onClick={() => trackEvent({ name: 'topdesk-link-header' })} className={styles[headerMiniLinkClassName]} target="_blank" rel="noreferrer noopener" href="https://atrias.topdesk.net/">{t('menu.actions.question')}</a>
            </AtriasTooltip>
          </div>

          <AuthenticatedTemplate>

            <NavLink
              to="/applications"
              activeClassName={styles['my-atrias-active']}
              id={styles['desktop-header-mini-link-container-my-atrias']}
            >
              <span className={styles[headerMiniLinkClassName]}>{t('menu.actions.my-atrias')}</span>
            </NavLink>

          </AuthenticatedTemplate>

          <div id={styles['desktop-header-mini-link-container-login']}>
            <LoginNavItem />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderMini;
