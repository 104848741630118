import React from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import HeaderMini from './HeaderMini';
import Toolbar from '../Navigation/Toolbar';

import './style.scss';

const Header = (props) => {
  const {
    onSideDrawerOpen,
    onSideDrawerClose,
    onSubMenuVisible,
    onSubMenuHidden,
  } = props;

  return (
    <Grid id="header-container" container direction="column" alignItems="center">
      <Grid container id="header" item direction="column" alignItems="center">
        <Hidden smDown>
          <HeaderMini />

        </Hidden>
        <Toolbar
          onSideDrawerOpen={onSideDrawerOpen}
          onSideDrawerClose={onSideDrawerClose}
          onSubMenuVisible={onSubMenuVisible}
          onSubMenuHidden={onSubMenuHidden}
        />
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  onSideDrawerOpen: PropTypes.func,
  onSideDrawerClose: PropTypes.func,
  onSubMenuVisible: PropTypes.func,
  onSubMenuHidden: PropTypes.func,
};

Header.defaultProps = {
  onSideDrawerOpen: null,
  onSideDrawerClose: null,
  onSubMenuVisible: null,
  onSubMenuHidden: null,
};

export default withWidth()(Header);
